#root {
  --green: #67633e;
  --light-green: #67633eab;
}

.sliderContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-sizing: border-box;
}

.sliderContent {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slideImage {
  width: 270px;
  height: 270px;
  border-radius: 100%;
  object-fit: cover;
}

.slideText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

.slideText h3 {
  color: var(--green);
}

.slideText h4 {
  font-size: 1.3rem;
  font-family: "Roboto Medium";
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: var(--green);
  border: none;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.button svg {
  width: 40px;
  height: 40px;
}

.button:hover {
  color: var(--light-green);
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50%;
  background: #a9a9a9;
  opacity: 0.5;
  border: none;
}

.dot:last-child {
  margin-right: 0;
}

.dot.active {
  opacity: 1;
  background: var(--green);
}

.titleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #67633e;
  border-radius: 10px;
}

.titleCard h3 {
  text-align: center;
}

.titleCard h4 {
  cursor: pointer;
  color: #67633e;
  font-size: 1.4rem;
  width: max-content;
  line-height: 4rem;
}

.container {
  display: flex;
  width: 100%;
}

.active {
  transform: scale(1.1);
  opacity: 1;
}

.inactive {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

/* Ajustes de responsividade */
@media screen and (max-width: 1200px) {
  .slideImage {
    width: 200px;
    height: 200px;
  }
  .slideText p {
    font-size: 1rem;
  }
  .slideText h4 {
    font-size: 1.1rem;
  }
  .slideText h3 {
    font-size: 1.3rem;
  }
  .container {
    flex-direction: column;
  }
  .titleCard h4 {
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .slideText {
    padding: 0;
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .slideText p {
    font-size: 0.9rem;
  }
  .slideText h4 {
    font-size: 1rem;
  }
  .slideText h3 {
    font-size: 1.2rem;
  }
  .slideImage {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 550px) {
  .prevButton {
    left: -10px;
  }
  .nextButton {
    right: -10px;
  }
  .slideText {
    padding-right: 2.2rem;
    padding-left: 2.2rem;
  }
  .slideText p {
    font-size: 0.8rem;
  }
  .slideText h4 {
    font-size: 0.9rem;
  }
  .slideText h3 {
    font-size: 1.1rem;
  }
  .slide {
    height: 77vh;
  }
  .slideImage {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 500px) {
  .slideImage {
    display: none;
  }
  .slideText h3 {
    font-size: 1.5rem;
  }
  .slideText h4 {
    font-size: 1.2rem;
  }
  .slideText p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .prevButton {
    left: -20px;
  }
  .nextButton {
    right: -20px;
  }
  .titleCard {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .titleCard {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .slideText p {
    font-size: 12px;
  }
  .slideText h4 {
    font-size: 14px;
  }
  .slideText h3 {
    font-size: 15px;
  }
}
