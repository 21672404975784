.containerAgenda {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 6rem;
  width: auto;
  margin-left: 6rem;
  margin-right: 6rem;
  height: 80vh;
}

.containerAgenda div h1 {
  margin-right: 0; /* Remover margem desnecessária */
  width: auto; /* Permitir que o tamanho seja baseado no conteúdo */
  text-align: center; /* Centralizar texto horizontalmente */
}

.containerAgenda div Button {
  margin-top: 2rem;
}
.containerAgenda div {
  width: 100%; /* Ajustar largura para centralizar */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Empilhar verticalmente */
}
.containerAgenda img {
  width: 50%;
  object-position: bottom;
}

.containerAgenda {
  display: flex;
  overflow: hidden;
  margin-bottom: 8rem;
}

.cardAgenda {
  height: 85vh;
  background: #f5f5f5;
  width: 50vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  transition: transform 0.5s ease;
  transform: translateX(0);
  margin-top: 0;
}

.contentAgenda {
  flex-direction: column;
  align-items: center;
  display: flex;
  font-size: 60px; /* ajuste conforme necessário para o design */
  text-align: center;
  color: #000;
  line-height: 2.5rem;
  width: 100%; /* Garante que ocupe toda a largura do container */
}

.titleAgenda {
  color: #67633e;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 70px;
  letter-spacing: 0.5rem;
  width: 100%; /* Ajuste para garantir centralização */
}

.subtitleAgenda {
  letter-spacing: 0.3rem;
}

.buttonAgenda:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.buttonAgenda {
  margin-top: 2rem;
  background-color: #67633e !important;
  color: #f5f5f5 !important;
  border: none !important;

  margin-bottom: 1.4rem !important;
  font-size: 1.5rem !important;
  border-radius: 30px !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.imgAgenda {
  height: 85vh;
  background: #f4f4f4;
  width: 50vw;
  object-fit: cover;
  transition: transform 0.5s ease; /* Animation duration and easing */
  transform: translateX(0); /* Initial position */
}

@media screen and (max-width: 1500px) {
  .containerAgenda {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

@media screen and (max-width: 1200px) {
  .containerAgenda {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 850px) {
  .containerAgenda {
    height: 100%;
  }

  .containerAgenda {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .containerAgenda img {
    width: 100%;
  }

  .cardAgenda {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    height: 20rem;
  }

  .contentAgenda {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleAgenda {
    font-size: 2.2rem;
  }

  .subtitleAgenda {
    font-size: 1.8rem;
  }

  .buttonAgenda {
    font-size: 1.2rem !important;
  }

  .imgAgenda {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .containerAgenda img {
    height: 60vh;
  }
}

@media screen and (max-width: 450px) {
  .containerAgenda {
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  .imgAgenda {
    width: 100vw;
  }
}
