/* Admin.module.css */

.photo {
  width: auto;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 1rem;
}

.title {
  text-align: center;
  margin-top: 8rem;
  font-size: 2rem;
  color: #333;
}

.postsContainer {
  width: 80%;
  margin: auto;
  margin-top: 3rem;
}

.postsContainer h1 {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 8rem;
  color: #555;
}

.postCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.postTitle {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.postContent {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.buttonEdit,
.buttonRemove {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 0.5rem;
}

.buttonEdit:hover {
  background-color: #0056b3;
}

.buttonRemove {
  background-color: #dc3545;
}

.buttonRemove:hover {
  background-color: #c82333;
}

.buttonRemove:focus {
  outline: none;
}

.buttonEditRem {
  margin-top: 3rem;
}
