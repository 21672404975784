:root {
  --green: #67633e;
  --white: #ffff;
}

.background {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/Components/PopUp/backgroundPopUp.png") no-repeat center
      center;

  background-size: cover;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.modal {
  --bs-modal-width: 90vw !important;
  border-radius: 8px;
}

.container {
  height: 85vh;
}

.content {
  color: var(--white);
  padding: 3.125rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}

.titlesContent {
  margin-bottom: 2.25rem;
}

.titlesContent h1 {
  color: var(--green);
  font-size: 3.75rem;
  font-weight: 700;
}

.subtitle {
  font-size: 2.2rem;
  font-style: italic;
  font-weight: 400;
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.25rem;
}

.list p {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
}

.listItem {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.check {
  width: 1.875rem;
  height: 1.875rem;
}

.container {
  display: flex;
  flex-direction: row;
}

.imgVector img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentButton {
  align-items: center;
  display: flex;
  justify-content: center;
}

.modal-body {
  padding: 0 !important;
  border-radius: 0 !important;
}

.modalHeader {
  background-color: #0f0f0f;
  /* Exemplo de fundo personalizado */
}

/* PopUp.module.css */
.customCloseButton {
  background-color: #ffffff !important; /* Cor de fundo personalizada */
  border-radius: 100% !important; /* Exemplo de borda arredondada */

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.customCloseButton:hover {
  background-color: var(--green); /* Cor ao passar o mouse */
}

.modalBody {
  padding: 0 !important;
  border-radius: 0 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in;
}

@media screen and (max-width: 1300px) {
  .list p {
    font-size: 32px;
  }

  .titlesContent h1 {
    font-size: 50px;
  }

  .subtitle {
    font-size: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .background {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/Components/PopUp/backgroundSemBlur.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgVector {
    display: none;
  }

  .content {
    width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .titlesContent h1 {
    font-size: 45px;
  }

  .subtitle {
    font-size: 30px;
  }

  .list p {
    font-size: 25px;
  }

  .check {
    width: 1.5rem;
    height: 1.5rem;
  }

  .container {
    height: 80vh;
  }
}

@media screen and (max-width: 400px) {
  .titlesContent h1 {
    font-size: 40px;
  }

  .subtitle {
    font-size: 25px;
  }

  .list p {
    font-size: 20px;
  }

  .check {
    width: 1rem;
    height: 1rem;
  }
}

@media screen and (max-width: 300px) {
  .titlesContent h1 {
    font-size: 35px;
  }

  .subtitle {
    font-size: 20px;
  }

  .list p {
    font-size: 16px;
  }

  .check {
    width: 1rem;
    height: 1rem;
  }
}
