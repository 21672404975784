.containerPost {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.contentTitulo {
  margin: 2rem;
}

.subtitle {
  color: #8a8a8a;
  font-size: 2rem;
}

.description {
  font-size: 1rem;
  color: #67633e;
}
.post {
  padding: 3rem;
}

.post img {
  border-radius: 10px;
}

.post h3 {
  margin-top: 2rem;
}

.title {
  font-size: 4rem;
}

.containerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.logo {
  width: 400px;
  height: auto;
  text-align: center;
}

.cardBlog {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 40vw;
  height: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.137);
  border-radius: 40px;

  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out; /* Transições para o card */
}

.title {
  margin-top: 50px;
}

.titleBlog {
  font-weight: 600;
}

.cardBlog:hover {
  transform: translateY(-8px); /* Movimento suave para cima */
}

.cardBlog:hover .imgBlog {
  transform: scale(1.02); /* Aplica zoom na imagem */
  transition: transform 0.5s ease-in-out; /* Suaviza a transição do zoom */
}

.contentBlog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.content {
  display: flex;

  flex-direction: column;
  align-items: center;
}
.contentBlog {
  overflow: hidden;
}

.cardContent {
  margin: 2rem 0;
}

.imgBlog {
  width: 100%;
  height: 50%;
  max-height: 400px;
  border-radius: 30px;
  object-fit: cover;
  filter: drop-shadow(0 0.5px 2px #00000031);
  transition: transform 0.3s ease-in-out;
  margin: auto;
}

.linkPost {
  text-decoration: none;
  color: #000000;
  margin: 2rem;
}

.msgNoPost {
  margin: 0 60px;
  font-size: 60px;
  text-align: center;
}

.dateBlog {
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .cardBlog {
    padding: 1rem;
  }
  .linkPost {
    margin: 1rem;
  }

  .titleBlog {
    font-size: 24px;
  }

  .msgNoPost {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  .contentBlog {
    flex-direction: column;
  }

  .cardBlog {
    width: 80vw;
  }

  .title {
    font-size: 50px;
  }

  .subtitle {
    font-size: 40px;
  }
  .msgNoPost {
    font-size: 30px;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 40px;
  }

  .subtitle {
    font-size: 30px;
  }
}
