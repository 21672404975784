body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Clash Display";
  src: local("Clash Display"),
    url("./assets/fonts/ClashDisplay_Complete/Fonts/Variable/ClashDisplay-Variable.ttf")
      format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf");
}

html ::-webkit-scrollbar {
  width: 10px;
}
html ::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #a9a9a9;
}
html ::-webkit-scrollbar-track {
  background: #ededed;
}
