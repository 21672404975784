.content {
  display: flex;
  flex-direction: column; /* Organiza o conteúdo em coluna */
  align-items: flex-start; /* Alinha os itens ao início, evitando deslocamentos horizontais */
  position: relative; /* Posicionamento relativo para o contêiner */
}

.content-lang {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  cursor: pointer; /* Adiciona o cursor de ponteiro para indicar clicabilidade */
}

.img-lang {
  width: 20px;
  height: auto;
}

.content-lang,
.lang {
  color: #fff;
}

.content-lang.scrolled,
.lang .scrolled {
  color: black; /* Substitua "newColor" pela cor desejada */
}

.lang {
  font-size: 12px;
  margin-left: 0.3rem;
}

.other-lang {
  position: relative;
  margin-top: 10px;
}

.list-lang {
  position: absolute;
  background-color: #67633eaf;
  border-radius: 10px;
}

.item-list-lang {
  display: flex;
  margin: 0.5rem 0;
  padding: 0 1rem;
  cursor: pointer;
}

.list-lang img {
  width: 20px;
  height: 15px;
}

.list-lang span {
  font-size: 12px;
  margin-left: 0.3rem;
}
