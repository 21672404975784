.content {
  margin: 8rem 12rem;
}

.introPost {
  margin-bottom: 2rem;
  width: 100%;
}

.titleBlog {
  font-size: 3rem;
  width: 100%;
  color: #67633e;
}

.postBlog {
  width: 60vw;
  max-height: 550px;
  object-fit: cover;
  border-radius: 20px;
  filter: drop-shadow(2px 4px 8px #0000007a);
}

.postBlogSection {
  height: 300px;
  object-fit: contain;
  border-radius: 20px;
  filter: drop-shadow(2px 4px 8px #0000007a);
  margin-bottom: 2rem;
}

.specialPostLaserlipolise {
  height: 100%;
  width: 400px;
}

.descricao {
  width: 100%;
  font-size: 18px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #3d3d3d;
}

.sectionTitle {
  color: #67633e;
}

.section {
  border-radius: 30px;
  padding: 0.5rem;
}

.section > div {
  width: 85%;
}

.relatedPosts {
  margin-top: 30px;
}

.relatedPosts h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
}

.relatedPostsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.relatedPostCard {
  width: 100%;
}

.relatedPostCard h3 {
  font-size: 16px;
}

.relatedPostPhoto {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.buttonModal {
  background-color: #67633e !important;
  border: none !important;
}

.buttonModal:hover,
.buttonModal:active {
  background-color: #000000 !important;
}

.backToBlog {
  margin-top: 30px;
  text-align: center;
}

.backButton {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #67633e;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

.backButton:hover {
  background-color: #000000;
}

.shareButtons {
  margin-top: 30px;
  text-align: center;
}

.shareButtons h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.socialButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.socialButtons svg {
  cursor: pointer;
  transition: transform 0.2s;
  color: #67633e;
}

.socialButtons svg:hover {
  transform: scale(1.1);
}

.copyLinkButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.copyLinkButton svg {
  color: #67633e;
}

.linkPost {
  color: #67633e;
  text-decoration: underline;
}

.subtitleSection {
  font-size: 18px;
  font-weight: bold;
  margin-top: 1rem;
}

.circleParallax .circle {
  background-color: #000000;
}

/* Responsividade para tablets e dispositivos menores */
@media screen and (max-width: 1024px) {
  .titleBlog {
    font-size: 2.5rem;
  }
  .section > div {
    width: 95%;
  }

  .postBlog {
    width: 75vw;
    max-height: 400px;
  }

  .content {
    margin: 8rem 4rem;
  }
}

/* Responsividade para dispositivos móveis */
@media screen and (max-width: 768px) {
  .content {
    margin: 2rem 2rem;
  }

  .introPost {
    margin-top: 6rem;
  }
  .descricao {
    font-size: 20px;
  }
  .relatedPostsList {
    flex-direction: column;
  }
  .backButton {
    font-size: 14px;
    padding: 8px 16px;
  }
  .section {
    padding: 1rem;
  }

  .section > div,
  .section > h2,
  .section > h3 {
    margin: 0.5rem;
  }
  .postBlog {
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .content {
    margin: 0.5rem;
  }
}
@media screen and (min-width: 1600px) {
  .content {
    padding: 0 4rem;
  }
}
@media screen and (min-width: 1800px) {
  .content {
    padding: 0 8rem;
  }
}
