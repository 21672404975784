* {
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
  height: 100%; /* Impede o scroll vertical */
}

.textoMenu {
  display: flex;
  color: #ffff;
  margin-top: 1rem;
  margin-left: 0.6rem;
  font-size: 0.6rem;
}

.scrolled .textoMenu {
  color: #000;
}

.navbar {
  background-color: rgba(0, 0, 0, 0.53);
  padding: 1em;
  top: 0;

  height: 4rem;
  filter: drop-shadow(0px 4px 100px #0000003a);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1000;
}

.container-fluid {
  padding: 0;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
}

#navHome {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
}

#navHome img {
  width: 6rem;
  height: auto;
}
.navbar-button {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: none;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

.navbar-button span {
  display: flex;
  flex-direction: row;
}

.offcanvas {
  width: 100% !important;
  filter: none !important;
  height: 100vh !important;
  background-color: #ffffffde !important;
  flex-direction: row !important;
}

.social-medias {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.containerButton {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
}
.button-cita {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  font-size: 0.8rem !important;
  border: none !important;
}

.button-cita:hover {
  background-color: #67633e !important;
  color: #ffff !important;
}
.item:hover {
  color: #67633e;
}
.item {
  margin-right: 18px;
  color: #ffffff;
  width: 20px;
  height: 20px;
}

.menu-lang {
  display: flex;
  align-items: center;
}

.close-btn img {
  border: 0;
  color: white;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: none;
}

.navbar-toggler button {
  color: none !important;
}

.vertical-offcanvas {
  display: flex;
  width: 5rem;
  height: 100vh;
  background-color: #67633e;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out;
  border-right: solid #f3f3f3 0.1px;
}

.vertical-offcanvas:hover {
  transform: translateX(5px) scaleX(1.2);
}

.nav-link {
  color: #67633e !important;
  font-size: 1.5rem !important;
  padding-top: 1.5vh !important;
  transition: transform 1s ease !important;
}

.navbar-nav li {
  display: inline-block;
  margin: 2px;
  padding: 5px 10px;
  color: #67633e !important;
  transition: 0.4s ease-in-out;
}
.navbar-nav li:hover {
  transform: scale(1.1);
}

.nav-link li:hover {
  color: #000 !important;
}

.navbar-nav li:not(:hover) {
  filter: brightness(0.8);
}

.disperse-top {
  transform: translateY(10px);
}

.disperse-center {
  transform: translateY(0px);
}

.disperse-bottom {
  transform: translateY(-10px);
}

.navbar-nav {
  display: flex;
  align-items: flex-start;
  position: relative;
  -webkit-box-orient: vertical;

  -webkit-box-align: start;
  padding: 8em 10vh 16em;
  margin-left: 8vw;
}

.offcanvas.open {
  backdrop-filter: blur(10px) !important;
}

.btnMenu {
  width: 2rem;
  height: auto;
  color: #ffff;
}

.scrolled {
  transition: background-color 0.1s ease, color 0.1s ease; /* Adicione uma transição suave */
  opacity: 1;
  background-color: #ffffff;
}

.scrolled .lang {
  color: #000;
}

.scrolled .btnMenu svg {
  color: #67633e;
}
.scrolled.navbar {
  border-bottom: #67633e 1px solid;
}

.scrolled .button-cita {
  background-color: #67633e !important;
  color: #ffff !important;
}

.scrolled .button-cita:hover {
  background-color: #000000 !important;
  color: #ffff !important;
}
.container-fluid {
  border: none !important;
}

.scrolled .navbar-toggler {
  color: #000 !important;
}

.scrolled .item {
  color: #67633e;
}

.scrolled .item:hover {
  color: #000000;
}

.white-bg {
  background-color: rgb(255, 255, 255);
  border-bottom: #67633e 1px solid;
}

.white-bg .item {
  color: #67633e;
  width: 30px;
  height: 30px;
}

.white-bg .button-cita {
  background-color: #67633e !important;
}

.white-bg .textoMenu {
  color: #000000;
}

.white-bg .button-cita:hover {
  background-color: #000000 !important;
  color: #ffff !important;
}
.down-arrow {
  margin-left: 0.5rem !important;
}
.collapsing {
  transition: height 0.3s ease;
  transform: rotate(360deg);
}

/* Adicione essa classe para deslizar suavemente os itens abaixo do collapse */
/* Adicione essa classe para deslizar suavemente os itens abaixo do collapse */
.slide-down {
  transform: translateY(0%);
  opacity: 1;
}

/* Adicione a transição para garantir um deslize suave */
.slide-down ul {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Adicione a classe para esconder os itens quando o collapse está fechado */
.slide-up {
  opacity: 0;
}

.rotate {
  transition: transform 0.3s ease-in-out; /* Adicione a transição */
}

.expanded .rotate {
  transform: rotate(
    180deg
  ); /* Aplique a rotação quando o submenu estiver expandido */
}

.nav-item {
  display: flex;
  justify-content: flex-start;
  margin: auto;
}

.logotipo {
  width: 60px;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.menu {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  color: #67633e;
  margin-left: 2rem;
}

.menu-item {
  transition: filter 0.3s ease; /* Suaviza a transição do filtro */
  filter: brightness(80%); /* Reduz o brilho para itens inativos */
}

.menu a {
  text-decoration: none;
  font-size: 1rem;
  transition: transform 0.5s ease-in-out;
  color: #67633e !important;
  padding: 0.5rem;
}

.menu a:hover {
  transform: scale(1.05);
}

.menu a:visited {
  color: #67633e;
}

.submenu-1 {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: auto;
  align-items: end;
}

.submenu-2 {
  display: none;
  position: absolute; /* Posiciona absolutamente em relação ao seu pai posicionado */
  left: 100%; /* Coloca o submenu à direita do item do menu */
  top: 0%; /* Alinha o submenu com o topo do item do menu */

  border-radius: 10px;
  padding-left: 0;
  flex-direction: column;
  min-width: 150px; /* Largura mínima */
  z-index: 1000;
}

.submenu-3 {
  display: none;
  position: absolute; /* Posiciona absolutamente em relação ao seu pai posicionado */
  left: 100%; /* Coloca o submenu à direita do item do menu */
  top: 0; /* Alinha o submenu com o topo do item do menu */
  width: 15rem; /* Largura do submenu, ajuste conforme necessário */

  border-radius: 10px;
  padding-left: 0;
  flex-direction: column;
}

.list-3 {
  color: #67633e;
}

.submenu-1,
.submenu-2,
.submenu-3 {
  display: none; /* Ensure all submenus are initially hidden */
}

.list-1 {
  display: flex; /* Adjust display settings for flex layout */
  flex-direction: column;
  color: #67633e;
  transition: filter 0.3s ease; /* Suaviza a transição do filtro */
  filter: brightness(
    70%
  ); /* Changed from row to column for better structure control */
}

.list-1:hover,
.list-2:hover {
  filter: brightness(90%); /* Restaura o brilho total para o item ativo */
}
.menu-item:hover > .submenu-1 {
  display: block; /* ou 'flex', dependendo da estrutura do layout */
}

.menu-item:hover {
  filter: brightness(100%); /* Restaura o brilho total para o item ativo */
}

.list-1 {
  background-color: #cfcfcf8a;
  width: 8rem;
  justify-content: end;
  align-items: end;
  margin-left: 2rem;
}

.list-1 a {
  text-decoration: none;
}

.list-2 {
  /* Provide padding for the second level submenu */
  font-size: 0.9rem;

  justify-content: start;
  display: flex;
  align-items: start;
  background-color: #cfcfcf8a;
  border-bottom: #969696 0.5px solid;
  width: 15rem;
}

.list-3 {
  padding: 0.5rem;
  background-color: #cfcfcf8a;
}

.list-1:hover .submenu-2 {
  display: flex; /* Mostra o submenu */
}

.list-2:hover > .submenu-3 {
  display: flex; /* ou 'block' dependendo da sua necessidade */
  flex-direction: column;
}
/* Specific hover rules to control display of nested submenus */
.menu > li:hover > .submenu-1 {
  display: flex; /* Show first level submenu on hover of main menu item */
}

.submenu-1 > .list-1:active > .submenu-2 {
  display: block; /* Show second level submenu on hover of first level list item */
}

.arrow-icon {
  transition: transform 0.5s ease-in-out;
}

.menu:hover .arrow-icon {
  transform: rotate(-180deg); /* Rotação de 180 graus quando hover */
}

.submenu-1:hover.arrow-icon {
  transform: rotate(180deg); /* Rotação de 180 graus quando hover */
}

.submenu-2:hover.arrow-icon {
  transform: rotate(-180deg); /* Rotação de 180 graus quando hover */
}

.arrow-icon:hover {
  transform: rotate(-180deg);
}

/* Estilos adicionais para itens de submenu */
.submenu-2 li a {
  display: block;

  color: black; /* Cor do texto */
  text-decoration: none; /* Remove o sublinhado */
}

.containerButtonOffCanvas {
  display: flex;
  margin-bottom: 2rem;
}

.button-cita-offCanvas {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  font-size: 0.8rem !important;
  border: none !important;
  margin-top: 2rem;
}

/* Estilo base para submenus */
/* Navbar.css */
.submenu {
  max-height: 0;
  overflow: hidden;

  visibility: hidden;
  opacity: 0;
}
.button-servicios .buttonGreen {
  font-size: 16px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.button-servicios .buttonBlack {
  font-size: 16px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-top: 3.5rem;
}
.menu-item a {
  user-select: none; /* Prevenir seleção de texto que pode causar deslocamento visual */
  -webkit-tap-highlight-color: transparent; /* Remover realce ao tocar em links em dispositivos iOS */
}
/* 
Estlização React Bootstrap */
.nav-item {
  margin: 0;
}
.dropdown-menu.show {
  align-items: end;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: auto;
  background-color: #a9a9a9;
}
@media (min-width: 1200px) {
  /* Bootstrap breakpoint para desktop */
  .offcanvas-custom {
    width: 80% !important; /* Define a largura para 50% da tela */
  }
}

@media screen and (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .navbar-nav a {
    padding: 1vh 0 !important; /* ou o valor que você preferir */
    font-size: 1rem !important; /* ou o valor que você preferir */
  }

  .offcanvas {
    flex-direction: row !important;
  }

  .offcanvas {
    width: 100vw;
  }
  .menu-item,
  .submenu-1,
  .submenu-2,
  .submenu-3 {
    display: none;
    width: 100%;
  }

  .submenu-1,
  .submenu-2,
  .submenu-3 {
    display: none;
    width: 100%;
    position: static;
  }

  .list-1,
  .list-2,
  .list-3 {
    background-color: transparent; /* Remover o fundo para não obstruir outros elementos */
    padding: 0.8rem; /* Ajustar o padding para toque */
  }

  .arrow-icon {
    display: inline-block; /* Garantir que a seta esteja visível ao lado do texto */
  }

  .menu ul ul {
    position: static; /* Altere a posição para 'static' para que o submenu apareça abaixo do item pai */
    display: none; /* Esconda o submenu por padrão */
  }

  .menu ul li:hover > ul {
    display: block; /* Exiba o submenu quando o item pai for hover */
    position: static; /* Altere a posição para 'static' */
    width: auto; /* Ajuste a largura conforme necessário */
    margin-top: 10px; /* Adicione um espaço entre o item pai e o submenu */
  }
  .menu-item {
    width: 100%;
  }

  .menu-item > a {
    display: block;
    width: 100%;
    padding: 10px; /* Ajuste conforme necessário para melhor tato */
  }

  .menu-item > a + .submenu {
    width: 100%;
  }

  .menu-item > a:hover + .submenu,
  .menu-item > a:focus + .submenu {
    height: auto; /* Abre o submenu ao tocar/clicar no link pai */
    max-height: 500px; /* Limita a expansão para evitar um layout quebrado */
  }
  .containerButton {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  /* Esconda a classe .navbar-toggler e .social-medias */
  .navbar-toggler,
  .social-medias a {
    display: none;
  }

  .navbar-brand {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .submenu-1,
  .submenu-2,
  .submenu-3 {
    position: static;

    width: 100%;
    background-color: transparent;
    padding-left: 0;
    border-radius: 0;
  }

  .menu li {
    display: block;
    width: 100%;
  }

  .menu li:hover .submenu-1,
  .list-1:hover .submenu-2,
  .list-2:hover .submenu-3 {
    display: flex;
    position: static;
    background-color: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-toggler,
  .social-medias a {
    display: none;
  }
}

@media screen and (min-width: 551px) {
  .containerButtonOffCanvas {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .vertical-offcanvas {
    width: 5.2rem;
  }

  .close-btn img {
    width: 3.2rem;
    height: 3.2rem;
  }
  /* 
  .list-1,
  .list-2,
  .list-3 {
    padding: 0.2rem;
    margin-left: 2rem;
  }
  .submenu-1 {
    margin-left: 1rem;
    background-color: #a9a9a957;
  }
  .submenu-2 {
    margin-left: 1.2rem;
    align-items: flex-end;
    border-bottom: 0px;
  }
  .submenu-3 {
    margin-left: 0;
    align-items: flex-end;
    border-bottom: 0px;
  } */
}
@media screen and (max-width: 450px) {
  .vertical-offcanvas {
    width: 4rem;
  }
  .close-btn img {
    width: 2.8rem;
    height: 2.8rem;
  }
  .menu {
    margin-left: 0.5rem;
  }
  .navbar-button span p {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  #navHome {
    display: flex;
    position: unset;
    transform: none;
  }

  .container-fluid {
    flex-direction: row-reverse;
  }

  .menu-lang {
    display: flex;
  }
}
@media screen and (max-width: 350px) {
  .vertical-offcanvas {
    width: 3rem;
  }
  .close-btn img {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (max-width: 315px) {
  .menu-lang {
    position: absolute;
    left: 0;
  }
  .navbar-brand {
    position: absolute;
  }
}

/* @media (min-width: 769px) {
  .submenu-1,
  .submenu-2,
  .submenu-3 {
    display: none; 
  }

  .menu-item:hover > .submenu-1 {
    display: block; 
  }


  .submenu-1,
  .submenu-2,
  .submenu-3 {
    display: none;
  }

  .menu-item:hover > .submenu-1 {
    display: block; 
  }

  
  .list-1:hover > .submenu-2 {
    display: block;
  }


  .list-2:hover > .submenu-3 {
    display: block;
  }
} */
