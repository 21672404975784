@import url("https://fonts.googleapis.com/css2?family=Cookie&family=Luckiest+Guy&family=Playwrite+VN+Guides&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.custom-modal {
  --bs-modal-width: 90vw !important;
  border-radius: 8px;
}

.modal-body {
  color: white;
  display: flex;
}

.custom-modal .modal-dialog {
  max-width: 90vw;
}

.custom-modal .modal-content {
  height: 80vh;
  overflow-y: auto;
  display: flex; /* Para alinhar os elementos */
  flex-direction: row; /* Horizontalmente */
}

.custom-modal .modal-body {
  padding: 6rem;
  display: flex;
  gap: 2rem; /* Espaço entre os elementos */
}

.formulario {
  justify-content: center;
  flex: 1; /* O formulário ocupará o restante do espaço */
}

.modal-content {
  --bs-modal-header-border-color: transparent;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  --bs-body-bg: transparent;
  --bs-modal-bg: #000000;
}

.button-close {
  --bs-btn-padding-x: 0.5rem !important;
  --bs-btn-padding-y: 0.1rem !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-border-color: #fff !important;
  --bs-btn-hover-bg: #fff !important;
  --bs-btn-hover-color: #000000 !important;
}

.content-image {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-image img {
  height: 100%; /* Ocupa 100% da altura do modal */
  width: auto; /* Mantém a proporção da imagem */
  object-fit: contain; /* Evita overflow cortando a imagem */
}

/* Por padrão, a imagem desktop será visível */
.image-desktop {
  display: block;
}

.image-mobile {
  display: none;
}

.button-close:active{
  background-color: #888888 !important;
  color: #ffffff !important;
  border: 0px !important;
}

/* Para telas menores que 1024px */
@media (max-width: 1024px) {
  .image-desktop {
    display: none;
  }
  .content-image {
    display: none;
  }
  .modal-content {
    background: linear-gradient(
        243deg,
        rgba(0, 0, 0, 0.5270483193277311) 0%,
        rgba(0, 0, 0, 0.07886904761904767) 50%,
        rgba(0, 0, 0, 0.5354516806722689) 100%
      ),
      url("../../assets/Gift/imgGiftMobile.jpg");
    background-position: center;
    background-size: contain;
  }
  .modal-body {
    width: 100%;
  }
  .content-text h1 {
    font-family: "Cookie";
    font-style: italic;
    display: flex;
    justify-content: right;
    font-size: 3rem;
    margin-top: 4rem;
  }
  .content-text h3 {
    font-size: 1rem;
    margin-top: 4rem;
  }
  .custom-modal .modal-body {
    padding: 1rem;
  }

  .modal-header {
    --bs-modal-header-padding: 0;
  }

 
  .custom-modal .modal-content {
    flex-direction: column;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0; /* Remove os paddings padrão */
    margin: 0;
  }

  .content-text {
    width: 100%; /* Ocupa toda a largura */
    text-align: center; /* Centraliza o texto */
    padding: 20px; /* Espaçamento interno */
    box-sizing: border-box; /* Inclui padding no cálculo de largura */
  }

  .formulario {
    width: 100%; 
  
    margin: 0 auto; 
  }

  .modal-header {
    border: none;
    justify-content: flex-end; 
  }

  .button-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff; /* Cor do botão */
  }
}

@media (max-width: 350px) {
  .content-text h1 {
    font-size: 2rem;
    margin-top: 1rem;
  }
  .content-text h3 {
    font-size: 0.8rem;
    margin-top: 1rem;
    
  }

  .content-text{
    padding: 0;
  }
}
