.containerForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-height: 30rem;
  filter: drop-shadow(1px 1px 20px #0000003a);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  width: 90%; /* Ajusta para 90% da largura da tela */
  max-width: 30rem;
}
.formControl {
  width: 26rem !important;

  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.titleForm {
  margin-top: 3rem;
  color: #67633e;
  font-size: 1.5rem;
}

.buttonFormulario {
  margin-bottom: 1rem;
}

@media screen and (max-width: 650px) {
  .formControl {
    width: 22rem !important;
  }
}
@media screen and (max-width: 550px) {
  .formControl {
    width: 18rem !important;
  }
}

@media screen and (max-width: 450px) {
  .formControl {
    width: auto !important;
  }
}

@media screen and (max-width: 350px) {
  .formControl {
    width: 12rem !important;
  }
}
