* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  max-width: 100vw; /* Garante que a largura máxima seja a do viewport */
  overflow-x: hidden; /* Esconde qualquer overflow horizontal */
  position: relative; /* Posiciona relativamente para ajustes adicionais */
}

:root {
  --green: #67633e;
  --gray: #a9a9a9;
  --light-gray: #a9a9a962;
  --black: #000000;
}

.link a {
  text-decoration: none;
  color: #67633e;
}

.link svg:hover,
.linkEnd:hover {
  color: #000000;
}

.containerIcons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;
  align-items: center;

  flex-wrap: wrap;
}
.contentIcons {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap; /* Permite que os itens passem para a próxima linha conforme necessário */
  justify-content: center; /* Centraliza os itens na linha */
  gap: 20px; /* Espaçamento entre os itens para melhor visualização */
}

.contentIcons svg {
  color: var(--green);
}
.circleBlack {
  width: 7.563rem;
  height: 7.563rem;
  background-color: #f5f5f5;
  border-radius: 50%;
  position: absolute;
  right: 8vw;
}

.circleEmpty {
  width: 5rem;
  height: 5rem;
  background: transparent;
  border: solid 1px #272727;
  border-radius: 50%;
  position: absolute;
  bottom: 0%;
  margin-left: 2rem;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;

  top: 20%;
  margin: auto;
  position: relative;
}

.containerButton {
  margin-left: 2rem;
  text-align: center;
}

.intro h2 {
  margin-top: 8rem;
  color: var(--black);
}

.introListTitle {
  display: flex;
  flex-direction: column;
}

.listIntro {
  width: 800px;
}

.linhaVertical {
  height: 350px; /*Altura da linha*/
  border-left: 1px solid;
  margin-left: 2rem;
  width: 0.5px;
  background-color: #000000;
  margin-top: 6rem;
}

.halfCircle {
  height: 4rem;
  width: 8rem;
  background-color: #a9a9a9;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  transform: rotate(-90deg);
  position: absolute;
  right: -2.1%;
}
.circle {
  width: 3.5rem; /* ajuste conforme necessário */
  height: 3.5rem; /* metade da largura */
  background-color: #a9a9a9; /* cor de fundo */
  border-radius: 50%;
  position: absolute;
  top: 70%;
  right: 5%; /* esconder a parte superior do semicírculo */
}

.intro p {
  font-weight: 600;
  color: var(--green);
}

.listIntro svg {
  color: var(--green);
  margin-right: 0.5rem;
}

.listIntro li {
  text-decoration: dotted;
  margin: 1rem;
}

.titleIntro {
  text-align: center;
  margin-top: 6rem;
}
.icon {
  width: 80px;
  height: auto;

  margin: auto;
}

.contentIcons div {
  padding: 0rem 2rem;
}

.containerIcons p {
  font-size: 16px;
}

.containerIcons h1 {
  width: 16vw;
}

.containerIcons h3 {
  font-size: 1.5rem;
}

.containerIcons p {
  width: 14rem;
  font-weight: 600;
}

.link {
  color: var(--green);
  text-decoration: none;
  display: flex;
}

.link svg {
  margin-right: 0.5rem;
}
.descricao {
  color: var(--gray);
}
.button {
  margin: 1rem auto !important;
  font-size: 1rem;
  border-radius: 10px !important;
  background-color: #000000 !important;
  border: none !important;
  border: #000000 solid 0.5px !important;
  padding: 0.2rem 1rem;
}

.button:hover {
  color: #000000 !important;
  background-color: #ffff !important;
  border: #000000 solid 0.5px !important;
}

.containerFormulario {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6rem;
}

.containerFormulario h2 {
  text-align: center;
}
.medias {
  margin: 0;
  display: flex;
  justify-content: center;
}

.medias svg {
  width: 30px;
  height: 30px;
}

.medias a {
  margin-right: 6px;
  margin-left: 6px;
}
.medias svg:hover {
  color: #000000;
}
.containerFormulario p {
  color: var(--gray);
  text-align: center;
  margin-bottom: 2rem;
}

.containerIcons h3 {
  margin-top: 1rem;
}

.containerTitleIcons {
  margin-bottom: 3rem;
}
@media screen and (max-width: 1600px) {
  .halfCircle {
    width: 7.8rem;
    height: 3.8rem;
  }
  .circle {
    width: 2rem;
    height: 2rem;
    top: 70%;
  }
}

@media screen and (max-width: 1500px) {
  .halfCircle {
    width: 6rem;
    height: 3rem;
    right: -7.5rem;
  }
  .circle {
    right: -4.5rem;
  }
}

@media screen and (max-width: 1400px) {
  .intro {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media screen and (max-width: 1300px) {
  .introListTitle {
    width: 600px;
  }
  .listIntro {
    width: 500px;
  }
  .halfCircle {
    width: 5rem;
    height: 2.5rem;
    right: -10rem;
  }
  .circle {
    right: -6rem;
  }
}

@media screen and (max-width: 1024px) {
  .intro {
    flex-direction: column;
  }

  .linhaVertical {
    display: none;
  }
  .containerButton {
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
  .halfCircle {
    display: none;
  }
  .circle {
    display: none;
  }
  .containerIcons,
  .intro {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-around;
  }

  .button {
    padding: 12px 24px; /* Botões maiores para tablets */
  }

  .intro {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .listIntro {
    padding: 0;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .intro {
    flex-direction: column;
  }

  .linhaVertical {
    display: none;
  }

  .containerIcons {
    flex-direction: column;
  }

  .button {
    width: 100%; /* Botões ocupam toda a largura em dispositivos móveis */
  }

  .introListTitle {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .halfCircle,
  .circle {
    display: none; /* Oculta elementos decorativos que não cabem bem em telas pequenas */
  }

  .intro h2,
  .containerFormulario h2 {
    font-size: 1.5rem; /* Reduz o tamanho da fonte para cabeçalhos em telas pequenas */
  }
}

@media screen and (max-width: 450px) {
  .containerTitleIcons {
    width: 100%;
  }
  .containerIcons {
    padding: 0;
  }
}

@media screen and (max-width: 400px) {
  .containerFormulario h2 {
    font-size: 1.2rem;
  }
}
