/* PostFilter.module.css */
.postFilter {
  display: flex;
  margin: 20px 2rem;
}

.filterButton {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 40px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filterButton:hover {
  background-color: #eee;
}

.filterButton.active {
  background-color: #67633e;
  color: white;
  border-color: #67633e;
}

.filterButton:not(.active) {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  .postFilter {
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterButton {
    margin-top: 8px;
  }
}

@media screen and (max-width: 450px) {
  .postFilter {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
