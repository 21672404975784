.container {
  display: flex;
  margin-top: 8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleAuth {
  margin-bottom: 2rem;
}

.formAuth {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
