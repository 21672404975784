.container {
  display: flex;
  flex-direction: row; /* Garante que os itens fiquem lado a lado */
  justify-content: center;
  align-items: flex-start;
  margin-left: 4rem;
  margin-right: 4rem;
  height: auto;
  margin-top: 3rem;
}
.wrapper {
  display: flex;
  flex-direction: row; /* Certifique-se que isso esteja definido para linha */

  flex-wrap: wrap; /* Permite que os itens se envolvam conforme o necessário */
}

.accordion {
  width: 360px;
  cursor: pointer;
}

.item {
  background-color: #67633e;
  margin-bottom: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  align-items: center;
  width: 100%;
}
.title {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.title p {
  margin-top: 4px;
}

.arrow svg {
  width: 1rem;
  height: 1rem;
}

.arrow {
  display: flex;
  margin-bottom: 1rem;
}
.content {
  color: #000000;

  overflow: hidden;
  height: 0; /* Alterado de max-height para height */
  transition: height 0.5s ease-in-out, padding 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
  padding: 0 20px; /* Mantenha o padding durante a transição para evitar que o conteúdo salte */
}

.content.show {
  padding: 10px 20px;
  height: auto; /* Alterado para 'auto' para se ajustar ao conteúdo */
  background-color: #ffffff;
  border-radius: 10px;
  overflow: visible;
  transition: height 0.5s ease, overflow 0s step-start,
    background-color 0.5 ease;
}

@media screen and (max-width: 1550px) {
  .accordion {
    width: 320px;
  }
  .container {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media screen and (max-width: 1300px) {
  .accordion {
    width: 285px;
  }
  .title p {
    font-size: 0.9rem;
  }
  .content {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .accordion {
    width: 260px;
  }
  .title p {
    font-size: 18px;
  }
  .content {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .accordion {
    width: 360px;
  }
}

@media screen and (max-width: 450px) {
  .accordion {
    width: auto;
  }
}
