/* Reset básico para garantir consistência */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: relative;
  display: flex;
  width: 100% !important;
  height: 80vh;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  flex-wrap: wrap;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#36363675, #0a0d3863);
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  flex-wrap: wrap;
}

.header h1 {
  margin: auto;
  margin-top: 50vh;
  margin-left: 6vw;
  font-family: "Roboto Medium";
  color: white;
}

.header p {
  margin: auto;
  margin-left: 6vw;
  color: white;
}

/* Media query para dispositivos com largura máxima de 400px */
@media screen and (max-width: 400px) {
  .header {
    height: 70vh; /* Altura reduzida para melhor adaptação */
    border-bottom-right-radius: 25px; /* Raio da borda ajustado para proporção menor */
    border-bottom-left-radius: 25px;
  }

  .backgroundImage,
  .overlay {
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}
