* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto Regular";
}

.fadeIn {
  opacity: 1; /* Muda para visível quando em viewport */
}

.slideRight {
  transform: translateX(-50px);
  animation: slideRightAnimation 2s forwards;
}

.slideLeft {
  transform: translateX(50px);
  animation: slideLeftAnimation 2s forwards;
}
@keyframes slideRightAnimation {
  from {
    transform: translateX(-50%); /* Começa 100% fora da tela à esquerda */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@keyframes slideLeftAnimation {
  from {
    transform: translateX(50%); /* Começa 100% fora da tela à direita */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}
video {
  width: 100%;
  object-fit: cover;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 0.25));
  display: flex;
  position: relative;
}

.containerHome {
  display: flex;
  flex-direction: column;
  max-width: 100vw; /* Limita a largura à tela */
  overflow: hidden;
}

.seta {
  width: auto;
  display: flex;
  position: absolute;
  top: 84vh;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: anima-seta 1.5s ease infinite;
}

@keyframes anima-seta {
  to {
    opacity: 1;
    transform: translateY(20px) translateX(-50%);
  }
}

.sectionTexto {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 565px;
  position: relative;
}

.titleFilosofia {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 7.625rem;
}

.texto {
  display: flex;
  width: 56vw; /* Largura definida */
  margin: auto; /* Margens automáticas para centralização horizontal */
  text-align: center; /* Centralizar o texto */
  font-size: 1.5rem;
  height: auto;
  color: #67633e;
}

.circleBlack {
  width: 7.563rem;
  height: 7.563rem;
  background-color: #a9a9a9b0;
  border-radius: 50%;
  position: absolute;
  right: 8vw;
}

.circleEmpty {
  width: 5rem;
  height: 5rem;
  background: transparent;
  border: solid 1px #272727;
  border-radius: 50%;
  position: absolute;
  margin-top: 300px;
  right: 82vw;
}

.button {
  display: block !important;
  margin: 1rem auto !important;
  justify-content: center;
  font-size: 1.4rem !important;
  border-radius: 10px !important;
  background-color: #67633e !important;
  border: none !important;
  border: #000000 solid 0.5px !important;
  padding: 0.6rem 1.4rem !important;
}

.link {
  text-decoration: none !important;
}

.button:hover {
  color: #000000 !important;
  background-color: #ffff !important;
  border: #000000 solid 0.5px !important;
}

.halfcircleLeft {
  width: 10.5vw; /* Largura do halfcircle */
  height: 100px; /* Altura do halfcircle */
  border-radius: 150px 150px 0 0; /* Arredondar cantos superior direito e superior esquerdo */
  background-color: transparent; /* Cor de fundo */
  left: 0px;
  transform: rotate(90deg) translateX(50px) translateY(50px);
  position: absolute;
  border: #000 solid 0.5px;
  margin: auto;
}

.halfcircleRight {
  width: 10.5vw; /* Largura do halfcircle */
  height: 100px; /* Altura do halfcircle */
  border-radius: 150px 150px 0 0; /* Arredondar cantos superior direito e superior esquerdo */
  background-color: transparent; /* Cor de fundo */
  transform: rotate(-90deg) translateX(-50px) translateY(50px);
  position: absolute;
  background: #000;
  right: 0;
  margin: auto;
}
.containerImgHome {
  display: flex;
  width: 100%;
  height: 30rem;
  overflow: hidden;
  margin-top: 12rem;
  margin-bottom: 14rem;
  position: relative;
}

.imgHome {
  display: flex;
  width: 100%; /* Garante que a largura inicial seja de 100% */
  height: auto; /* Altura ajustada automaticamente para manter a proporção */

  position: relative;
  background-image: url("../../assets/images_home/imgHome.webp");
  background-size: cover;
  background-position: center 20%;
}

.containerFrase {
  position: relative;
}

.frase {
  font-size: 2.5rem;
  font-family: "Roboto Medium";
  color: #67633e;
  margin-top: 5rem;
  position: absolute;
  z-index: 1;
  margin-left: 8rem;
  width: 20vw;
}

.zoomIn {
  animation: zoomInEffect 5s ease forwards;
}

@keyframes zoomInEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
.textHome {
  font-size: 60px;
  width: 17.25rem;
  position: absolute;
  left: 0;
  color: #000;
  margin-top: 10vh;
  margin-left: 2vw;
}

.containerImg {
  position: relative;
}

.buttonInfoSobre {
  position: absolute;
  left: 10%;
  bottom: 15%;
  color: #67633e;
}

.buttonInfoFilosofia {
  position: absolute;
  left: 25%;
  bottom: 15%;
  color: #67633e;
}

.textoInfoSobre {
  position: absolute;
  bottom: 8%;
  left: 8.5%;
  color: #ffff;
}
.textoInfoFilosofia {
  position: absolute;
  bottom: 8%;
  left: 23.5%;
  color: #ffff;
}
.iconInfo {
  width: 4rem;
  height: 4rem;
}

.iconInfo:hover {
  color: #ffff;
}

.buttonCarousel {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.buttonCarousel a {
  text-decoration: none;
  text-align: center;
}
.containerImgTexto {
  display: flex;
  flex-direction: row; /* Inicialmente em linha */
  align-items: center; /* Centraliza os itens verticalmente */
  justify-content: center; /* Centraliza os itens horizontalmente */
  margin-top: 2rem;
  max-width: 1400px; /* Largura máxima para evitar que fique muito largo em telas grandes */
  margin-left: auto; /* Centraliza o container na página */
  margin-right: auto; /* Centraliza o container na página */
}

.containerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 6rem;
}

.subtitleInfo {
  color: #67633e;
  font-family: "Roboto Medium";
  transition: opacity 2s ease, transform 2s ease;
  font-size: 28px;
  margin-bottom: 6px;
}

.titleInfo {
  font-size: 22px;
  margin-bottom: 2rem;
  color: #a9a9a9;
}

.infoImg {
  border-radius: 10px;
  background-size: cover;

  width: 595px; /* Adicionado limite máximo de largura */
  height: 681px; /* Adicionado limite máximo de altura */
  margin-bottom: 3vh;
  margin-right: 6rem;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.158));
  background-image: url("../../assets/images_home/imgHomeInicio.webp");
  transition: opacity 1s ease-in-out;
  background-position: top center;
  transform: translateX(-100vw);
}

.containerConteudo {
  width: 90vw; /* Alterado para uma largura relativa */
}

.conteudo {
  justify-content: flex-end;
  line-height: 1.5rem;
  width: auto;
  text-align: baseline;
  font-size: 16px;
}

.assinatura {
  font-style: italic;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin: auto;
  margin-top: 2vh;
}

.circle01 {
  width: 14.5rem; /* Largura do círculo */
  height: 14.5rem; /* Altura do círculo */
  border-radius: 50%; /* Define um raio de borda igual a metade da largura/altura */
  background-color: #d9d9d9; /* Cor de fundo (altere conforme necessário) */
  position: relative;
  top: 20px; /* Posição do topo em relação ao elemento pai */
  left: 30px; /* Posição da esquerda em relação ao elemento pai */
}
.trailsText {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.circle02 {
  width: 14.5rem; /* Largura do círculo */
  height: 14.5rem; /* Altura do círculo */
  border-radius: 50%; /* Define um raio de borda igual a metade da largura/altura */
  background-color: #4d493e; /* Cor de fundo (altere conforme necessário) */
  position: absolute;
  margin-top: 10.313rem;
  margin-left: 7.938rem;
}

.consultas {
  display: flex;
  flex-direction: column;
  margin-top: 12rem;
  margin-bottom: 8rem;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex; /* Align button to the right */
  margin: auto;
}

.toggleButton {
  background-color: transparent !important;
  color: black !important;
  font-weight: normal;
  border: none !important;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
  margin-left: auto;
}

.selected {
  font-weight: bold !important;
}

.buttonAgenda:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.buttonAgenda {
  margin-top: 2rem;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: none !important;
  padding: 0.5rem 1.6rem !important;
  font-size: 1.5rem !important;
}
.collapseContent {
  margin-left: 10px;
  overflow: hidden; /* Ensure that the content is not visible until the animation starts */
  animation: moveToRight 2s ease-in-out;
  animation-delay: 1000ms; /* Initially set to 0 */
}

.collapseContent.in {
  max-width: 500px; /* Set to the desired max-width for the expanded content */
}

.containerAgenda {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 6rem;
  width: 1400px;
  height: 70vh;
}

.consultasDescricao {
  display: flex;
  flex-direction: column;
}

.consultasDescricao p {
  width: 289px;
}

.buttonAgenda:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.buttonAgenda {
  margin-top: 2rem;
  background-color: #67633e !important;
  color: #f5f5f5 !important;
  border: none !important;

  margin-bottom: 1.4rem !important;
  font-size: 1.5rem !important;
  border-radius: 30px !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.containerAgenda {
  display: flex;
  overflow: hidden;
  margin-bottom: 8rem;
}

.contentAgenda {
  flex-direction: column;
  align-items: center;
  display: flex;
  font-size: 60px; /* ajuste conforme necessário para o design */
  text-align: center;
  color: #000;
  line-height: 2.5rem;
  width: 100%; /* Garante que ocupe toda a largura do container */
}

.titleAgenda {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 70px;
  letter-spacing: 0.5rem;
  width: 100%; /* Ajuste para garantir centralização */
}

.cardAgenda {
  height: 100%;
  background: #a9a9a9;
  width: 50vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  transition: transform 0.5s ease;
  transform: translateX(0);
  margin-top: 0;
}

.subtitleAgenda {
  letter-spacing: 0.3rem;
}

.imgAgenda {
  height: 85vh;
  background: #f4f4f4;
  width: 50vw;
  object-fit: cover;
  transition: transform 0.5s ease; /* Animation duration and easing */
  transform: translateX(0); /* Initial position */
}
.animate.animated {
  transform: translateX(0);
}

.containerIcons {
  height: auto;
  background-color: #ffff;

  width: 1400px;
  display: flex;
  flex-direction: row;
  margin: auto;
  border-radius: 10px;
  filter: drop-shadow(4px 4px 8px #0000001a);
  justify-content: center;
  align-items: center;
}

.icons01 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 0;
  width: 100%;
}

.icons02 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.iconTexto {
  display: flex;

  margin-left: 2rem;
  margin-right: 2rem;
  color: #000000;
  font-size: 1rem;
}

.icon {
  width: 4rem;
  height: auto;
  margin-right: 3rem;
  margin-left: 3rem;
  color: #67633e;
  margin-top: 1.5rem;
}

.textRotate {
  animation: curvar-texto 2s linear infinite;
}

.titleBlog {
  color: #67633e;
  text-align: center;
  font-family: "Roboto Medium";
}

.descricaoBlog {
  color: #a9a9a9;
}
.subtitleBlog {
  font-size: 1.2rem;
  width: 40rem;
  margin-right: 2rem;
}

.carouselBlog {
  display: flex;
}
.contentBlog {
  display: flex;

  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.containerCarousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 8rem;
}

.containerBlog {
  display: flex;
  max-width: 1400px;
}
.sectionComentarios {
  margin: auto;
  justify-content: center;
  margin-top: 15rem;
  margin-bottom: 20vh;
  width: 100%;
  height: auto;
  background-color: #a9a9a9;
}

.perguntas {
  margin-bottom: 3rem;
}

.titleComentarios {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
  .containerInfo {
    width: 100%;
    margin: auto;
  }
  .containerImgTexto {
    max-width: 100%;
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .containerIcons {
    width: auto;
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .consultas {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .imgHome {
    background-position: bottom center;
  }
}

@media screen and (max-width: 1700px) {
  .buttonInfoFilosofia {
    left: 27%;
  }

  .textoInfoFilosofia {
    left: 25%;
  }
  .frase {
    font-size: 2.3rem;
  }
  .iconTexto {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1600px) {
  .buttonInfoFilosofia {
    width: 3.5rem;
    height: 3.5rem;
    top: 74%;
  }

  .textoInfoFilosofia {
    left: 25%;
    font-size: 0.9rem;
  }

  .buttonInfoSobre {
    width: 3.5rem;
    height: 3.5rem;
    top: 74%;
  }

  .textoInfoSobre {
    font-size: 0.9rem;
  }
  .infoImg {
    width: 30rem;
  }
}

@media screen and (max-width: 1500px) {
  .containerCarousel {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .containerImgTexto {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .containerIcons {
    margin-left: 4rem;
    margin-right: 4rem;
    width: auto;
  }
  .containerAgenda {
    width: 1200px;
  }
}

@media screen and (max-width: 1450px) {
  .subtitleBlog {
    width: auto;
    font-size: 1.2rem;
  }
  .contentBlog {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1400px) {
  .conteudo {
    font-size: 0.9rem;
  }

  .infoImg {
    width: 28rem;
  }
}

@media screen and (max-width: 1350px) {
  .containerImgTexto {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .infoImg {
    width: 26rem;
  }
  .frase {
    font-size: 2.2rem;
    margin-top: 6rem;
  }
  .containerIcons {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1300px) {
  .subtitleInfo {
    font-size: 25px;
  }
  .conteudo {
    font-size: 16px;
  }
  .infoImg {
    width: 25rem;
  }
  .frase {
    font-size: 2rem;
    margin-top: 7rem;
    margin-left: 4rem;
  }
}

@media screen and (max-width: 1250px) {
  .titleBlog {
    width: auto;
    font-size: 2.2rem;
  }
  .descricaoBlog {
    font-size: 1.4rem;
  }
  .subtitleBlog {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1200px) {
  .subtitleInfo {
    font-size: 24px;
  }
  .conteudo {
    font-size: 16px;
  }
  .assinatura {
    font-size: 12px;
  }
  .titleBlog {
    width: auto;
    font-size: 2.1rem;
  }
  .descricaoBlog {
    font-size: 1.3rem;
  }
  .subtitleBlog {
    font-size: 1rem;
  }
  .conatainerCarousel {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .containerAgenda {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
@media screen and (max-width: 1100px) {
  .textHome {
    width: 30vw;
  }

  .conteudo,
  .assinatura {
    font-size: 0.69rem;
  }

  .texto {
    font-size: 1.4rem;
  }
  .circleBlack {
    width: 5.88rem;
    height: 5.88rem;
  }
  .circleEmpty {
    width: 3.89rem;
    height: 3.89rem;
  }

  .containerImgTexto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .infoImg {
    height: 40rem;
    width: 35rem;
    margin-right: 0;
  }

  .subtitleInfo {
    font-size: 1.2rem;
    text-align: center;
  }
  .titleInfo {
    font-size: 1rem;
    text-align: center;
  }
  .conteudo {
    font-size: 0.9rem;
  }
  .iconTexto {
    font-size: 1rem;
    text-align: center;
  }

  .containerCarousel {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media (max-width: 1024px) {
  .circleBlack {
    width: 100px;
    height: 100px;
  }

  .circleEmpty {
    width: 66.12px;
    height: 66.12px;
  }

  .texto {
    font-size: 26.45px;
  }

  .iconInfo {
    font-size: 0.8rem;
  }

  .iconTexto {
    font-size: 0.8rem;
  }
  .containerIcons {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .frase {
    font-size: 1.9rem;
  }

  .containerCarousel {
    flex-direction: column;
    margin: auto;
  }
  .contentBlog {
    margin: auto;
    text-align: center;
    margin-bottom: 3rem;
  }
  .titleBlog {
    font-size: 2.5rem;
    margin: auto;
  }
  .descricaoBlog {
    font-size: 1.6rem;
    margin: auto;
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
  }
  .subtitleBlog {
    width: 26rem;
    margin: auto 0;
  }

  .containerBlog {
    flex-direction: column;
  }

  .imgHome {
    background-image: linear-gradient(
        243deg,
        rgba(0, 0, 0, 0.72) 0%,
        rgba(0, 0, 0, 0.199) 50%,
        rgba(0, 0, 0, 0.479) 100%
      ),
      url("../../assets/images_home/imgHome02.webp");
  }

  .buttonBlog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@media screen and (max-width: 950px) {
  .frase {
    font-size: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .button {
    font-size: 24px;
  }

  .textHome {
    font-size: 40px;
  }

  .infoImg {
    width: 28rem;
    height: 32rem;
  }

  .assinatura {
    font-size: 0.7rem;
  }

  .subtitleInfo {
    font-size: 1.1rem;
  }
  .titleInfo {
    font-size: 1rem;
  }

  .circleBlack {
    right: 7vw;
  }

  .circleEmpty {
    right: 83vw;
  }
  .icon {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .iconTexto {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 0.7rem;
  }

  .iconInfo {
    width: 3rem;
    height: 3rem;
    top: 30%;
  }

  .textoInfoFilosofia {
    font-size: 0.7rem;
  }
  .textoInfoSobre {
    font-size: 0.7rem;
  }
  .titleAgenda {
    font-size: 2.2rem;
  }

  .subtitleAgenda {
    font-size: 1.8rem;
  }

  .contentAgenda {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardAgenda {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    height: 20rem;
    width: 100%;
  }
  .imgAgenda {
    width: 100%;
  }

  .buttonAgenda {
    font-size: 1.2rem !important;
  }
  .containerAgenda {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .iconInfoSobre {
    top: 48%;
  }
  .textoInfoSobre {
    top: 86%;
  }
  .textoInfoFilosofia {
    top: 86%;
  }
}
@media screen and (max-width: 768px) {
  .texto {
    font-size: 24px;
  }

  .button {
    padding: 0.2rem 1rem;
    font-size: 1rem;
  }

  .textHome {
    font-size: 35px;
  }

  .infoImg {
    width: 25rem;
    height: 30rem;
  }
  video {
    height: 70vh;
  }
  .conteudo {
    font-size: 0.8rem;
  }
  .assinatura {
    font-size: 0.7rem;
  }

  .containerIcons {
    display: flex;
    flex-direction: column;
  }

  .icons01 {
    justify-content: center;
  }

  .icons02 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .textoInfoFilosofia {
    top: 87%;
  }
  .textoInfoSobre {
    top: 87%;
  }
  .iconTexto {
    width: 100%;
    justify-content: center;
  }

  .imgHome {
    width: 100vw; /* Ensure it starts with 100% of the viewport width */
    height: auto; /* Maintain aspect ratio */
    background-image: linear-gradient(
        243deg,
        rgba(0, 0, 0, 0.72) 0%,
        rgba(0, 0, 0, 0.199) 50%,
        rgba(0, 0, 0, 0.479) 100%
      ),
      url("../../assets/images_home/imgHome02.webp");
    background-size: cover;
    background-position: calc(70% + 20px) center;

    transform: none;
  }

  .zoomIn {
    animation: zoomInEffectMobile 5s ease forwards;
  }

  @keyframes zoomInEffectMobile {
    0% {
      transform: scale(1); /* Start with no scaling */
    }
    100% {
      transform: scale(1.3); /* Ends at 130% scale */
    }
  }

  .infoImg.slideRight {
    animation: none;
  }

  .infoImg {
    transform: translateX(0);
  }
  .frase {
    margin-left: 1rem;
    width: 18rem;
    margin-top: 2rem;
  }

  .containerAgenda img {
    height: 60vh;
  }
}

@media screen and (max-width: 650px) {
  .circleBlack {
    right: 6vw;
  }
  .infoImg {
    width: 22rem;
    height: 28rem;
  }

  .buttonInfoFilosofia {
    left: 30%;
  }
  .textoInfoFilosofia {
    left: 27%;
  }
  .titleBlog {
    font-size: 2.2rem;
  }
  .descricaoBlog {
    font-size: 1.4rem;
  }
  .subtitleBlog {
    font-size: 0.9rem;
    width: 24rem;
  }
}

@media screen and (max-width: 600px) {
  .containerFrase {
    justify-content: justify;
  }
  .frase {
    text-align: start;
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 550px) {
  .texto {
    font-size: 20px;
  }

  .containerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .containerImgTexto {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .infoImg {
    width: 24rem;
    height: 95vw;
    display: flex;
    justify-content: center;

    margin-left: 1rem;
    margin-right: 1rem;
  }

  .button {
    padding: 0.2rem 1rem;
    font-size: 1rem;
  }

  .textHome {
    width: auto;
    margin-top: 3vh;
    display: flex;
    margin: auto 0;
    text-align: center;
    justify-content: center;
    position: absolute;
  }

  .circleBlack {
    right: 2vw;
  }

  .containerIcons {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .icons01 {
    display: flex;
    flex-direction: column;
  }
  .frase {
    font-size: 2rem;
  }
  .textoInfoFilosofia {
    top: 89%;
  }
  .textoInfoSobre {
    top: 89%;
  }
  .subtitleInfo {
    margin-top: 2rem;
    line-height: 1.8rem;
  }

  .iconInfo {
    width: 2.8rem;
    height: 2.8rem;
    top: 22%;
  }

  .textoInfoFilosofia {
    font-size: 0.6rem;
  }
  .textoInfoSobre {
    font-size: 0.6rem;
  }

  .icon {
    width: 6rem;
    height: 6rem;
  }

  .conatainerCarousel {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .containerImgHome {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .circleBlack {
    right: -1vw;
  }

  .containerFrase {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }
  .frase {
    font-size: 1.8rem;
  }
  .buttonInfoFilosofia {
    left: 34%;
    top: 75%;
  }
  .buttonInfoSobre {
    left: 14%;
    top: 75%;
  }
  .textoInfoFilosofia {
    left: 32%;
    top: 90%;
  }
  .textoInfoSobre {
    left: 13%;
    top: 90%;
  }
  .infoImg {
    width: 20rem;
  }

  .subtitleBlog {
    width: 20rem;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .buttonInfoFilosofia {
    left: 40%;
    top: 70%;
  }
  .buttonInfoSobre {
    left: 16%;
    top: 70%;
  }
  .textoInfoFilosofia {
    left: 37%;
    top: 86%;
  }
  .textoInfoSobre {
    left: 14.2%;
    top: 86%;
  }

  .containerInfo {
    margin-top: 3rem;
  }
  .infoImg {
    width: 18rem;
    height: 24rem;
  }
  .containerInfo {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .conteudo {
    text-align: baseline;
  }

  .titleBlog {
    font-size: 1.9rem;
  }
  .descricaoBlog {
    font-size: 1.4rem;
  }
  .subtitleBlog {
    font-size: 0.8rem;
    width: 17rem;
  }
  .containerFrase {
    margin-left: 0;
    margin-right: 0;
  }
  .iconTexto {
    font-size: 16px;
  }
  .buttonBlog {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 400px) {
  .circleBlack {
    right: -8vw;
  }

  .infoImg {
    width: 18rem;
  }
  .buttonInfoFilosofia {
    top: 67%;
  }
  .buttonInfoSobre {
    top: 67%;
  }

  .titleBlog {
    font-size: 1.7rem;
  }
  .descricaoBlog {
    font-size: 1.3rem;
  }
  .imgHome {
    width: 100vw;
    background-size: cover;
    height: 50vh;
  }

  .frase {
    font-size: 1rem;
    width: 16rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 350px) {
  .titleBlog {
    font-size: 1.4rem;
  }

  .subtitleBlog {
    font-size: 0.6rem;
    width: 15rem;
  }
}

@media screen and (max-width: 300px) {
  .frase {
    font-size: 1.5rem;
  }

  .infoImg {
    width: 95vw;
  }

  .containerImgTexto {
    padding: 10px;
  }
  .subtitleInfo {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .containerConteudo {
    width: 90vw;
  }
}
.container {
  overflow-x: hidden;
}

.image-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  animation: slideAndRotate 2s linear forwards;
}

@keyframes slideAndRotate {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%) rotate(180deg);
  }
  100% {
    transform: translateX(0%);
  }
}
.container {
  position: relative;
  overflow-x: hidden; /* Garante que a imagem não ultrapasse o contêiner */
}

.image-container {
  position: absolute;
  right: -100%; /* Inicialmente, posiciona a imagem fora da tela */
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.5s ease-in-out; /* Adiciona uma transição suave */
}

.animation-active {
  right: 0; /* Move a imagem para a posição inicial */
}

.ParallaxLayer {
  position: absolute;
  width: 100%;
}

.ParallaxLayer:nth-child(odd) {
  z-index: 1; /* Coloque as camadas ímpares atrás das camadas pares */
}

.ParallaxLayer:nth-child(even) {
  z-index: 2; /* Coloque as camadas pares na frente das camadas ímpares */
}
