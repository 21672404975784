.buttonWhite {
  margin: 1rem auto !important;

  margin-top: 2rem !important;
  border-radius: 30px !important;
  font-size: 1.5rem !important;
  border: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transition: transform 0.5s ease-in-out, background-color 0.9s ease-in-out !important;
}

.buttonWhite:hover {
  transform: translateY(-10px) !important;
  background-color: #000000 !important;
  color: #ffffff !important;
}
