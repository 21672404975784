.sectionComentarios {
  margin: auto;
  justify-content: center;
  margin-top: 15rem;
  width: 100%;
}

.containerComentarios {
  width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.respostas {
  background-color: #f5f5f5 !important;
  font-size: 18px;
}

.perguntas button {
  font-size: 18px;
}

.accordionHeader {
  width: 48rem;
}
@media screen and (max-width: 1024px) {
  .accordionHeader {
    width: 35rem;
  }
}

@media screen and (max-width: 850px) {
  .accordionHeader {
    width: 26rem;
  }
}
@media screen and (max-width: 768px) {
  .accordionHeader {
    width: 80vw;
  }
}
