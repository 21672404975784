body {
  font-family: "Verdana";
}

.sectionFooter {
  padding: 2rem 4rem;
}

.footer {
  background-color: #161616;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: 8rem;
  width: 100%;
}

#colunaLogo {
  margin-top: -2rem;
}
#sbFooter {
  display: flex;
  flex-direction: column;
}

.sectionLink {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-top: 2rem;
}

.button {
  margin-top: 2rem !important;
  border-radius: 30px !important;
  font-size: 16px;
  border: none !important;
  background-color: #67633e !important;

  transition: transform 0.5s ease-in-out, background-color 0.9s ease-in-out !important;
}

.button:hover {
  color: #000000 !important;
  background-color: #ffff !important;
  transform: translateY(-10px) !important;
}

.footerLink {
  width: auto;
  margin: 1rem;
  display: flex;
  max-width: 250px;
  flex-direction: column;
  color: rgb(255, 255, 255);
  margin-right: 1rem;
  margin-left: 1rem;
}

.columnsLinks {
  display: flex;
}

a {
  color: rgb(175, 175, 179);
  text-decoration: none;
}

.socialMedia {
  display: flex;
  flex-direction: row;
}

.socialMedia a {
  color: #67633e;
}

.socialMedia a:hover {
  color: #ffff;
}

.iconLink a {
  display: flex;
  align-items: center;
}
.iconLink a svg {
  color: #67633e;
  margin-right: 0.5rem;
}

.iconLink svg:hover {
  color: #ffff;
}
.iconInfo {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.4rem;
  color: #67633e;
}

.iconInfo:hover {
  color: #ffff;
}

.iconLocation {
  width: 1.6rem;
  height: 1.6rem;
}

.footerLink h4 {
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 0.9rem;
  width: 10rem;
  color: #67633e;
}

.subTitle {
  font-size: 14px !important;
  font-weight: 500;
}

.logo {
  margin-bottom: 0.9rem;
  width: 10rem;
}
.footerLink p {
  font-size: 16px;
  line-height: 1.4rem;
  margin: 0.5rem 0;
  cursor: pointer;
  width: auto;
}

.footerLink a p {
  color: #ffff;
}

.footerLink a p:hover {
  color: #67633e;
}
.footerLink a {
  margin-right: 0.5rem;
  text-decoration: none;
}

.footerLink div {
  display: flex;
  flex-direction: row;
}

.buttonFooter {
  font-size: 16px !important;
}

.footerBelow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
  font-size: 13px;
}

.bellowLinks {
  flex-direction: row;
  display: flex;
}

.bellowLinks p {
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

hr {
  height: 3px; /* Espessura da linha */
  border: none; /* Remove a borda padrão */
  background-color: #ffffff; /* Define a cor da linha */
  width: 100%;
  border-radius: 6px;
}

.footerCopyright p {
  font-size: 13px;
  line-height: 10px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .sectionFooter {
    padding: 4rem 2rem;
  }
  .footerLink {
    width: 200px;
  }
}

@media screen and (max-width: 1040px) {
  .footerLink {
    width: 180px;
  }
  .sectionFooter {
    padding: 4rem 1rem;
  }
}

@media screen and (max-width: 950px) {
  .footerLink {
    flex-wrap: wrap;
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sectionLink {
    width: 90%;
    justify-content: center;
  }
  .sectionFooter {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  .sbFooter h1 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .footerLink {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .footerLink {
    width: 180px;
  }
  .iconLink {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .sbFooter h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .footerBelow {
    flex-direction: column;
    justify-content: left;
  }

  .bellowLinks {
    flex-direction: column;
  }
  .bellowLinks p {
    margin-left: 0rem;
    margin-top: 1rem;
  }
  #sbFooter {
    flex-direction: column; /* Organiza todos os elementos em uma coluna */
  }
}

@media screen and (max-width: 400px) {
  .footer h1 {
    font-size: 27px;
    line-height: 38px;
  }
  #linkEmail {
    font-size: 12px;
  }
}
