* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.textHeader {
  margin: auto;
  text-align: center;
  justify-content: center;
}

.containerSobre {
  display: flex;
  flex-direction: row;

  justify-content: center;
  width: 1400px;
  align-items: center;
  margin: auto;
  margin-top: 3rem;
}

#containerSobreAdd {
  margin-top: 8rem;
}
.containerTexto p {
  font-size: 16px;
}

.containerTexto {
  display: flex;
  flex-direction: column;
  margin-left: 6rem;
}

.containerTexto2 h3 {
  color: #67633e;
}
.containerTexto2 p {
  font-size: 16px;
}
.containerTexto2 {
  display: flex;
  flex-direction: column;
}
.imgContainer {
  width: 50%;
}

.imgPerfil {
  width: 595px; /* Adicionado limite máximo de largura */
  height: 681px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 3s ease;
  transform: translateX(-100vw);
}

.containerTexto h1 {
  margin-top: 0;
  color: #67633e;
}

.containerTexto p {
  margin-right: 4vw;
}

@media screen and (max-width: 768px) {
  .containerSobre {
    flex-direction: column;
  }

  .imgContainer,
  .containerTexto {
    width: 100%;
  }
}

.containerFrase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 1400px;
  margin: auto;
}

.containerFrase h1 {
  margin-top: 10vw;
  width: 30.188rem;

  margin-bottom: 10vw;
  color: #67633e;
}

.circle {
  width: 13rem; /* Adjust as needed */
  height: 13rem;
  background-color: #000000; /* Change as needed */
  border-radius: 10rem;

  margin-bottom: 10vh;
  margin-top: 6vw;
  position: absolute;
  right: 0;
}

.circleSmall {
  width: 4rem; /* Adjust as needed */
  height: 4rem;
  background-color: #000000; /* Change as needed */
  border-radius: 10rem;
  position: absolute;
  right: 0;
  margin-top: 18vw;
  margin-right: 14vw;
}

.imagemAtendimento {
  width: 100vw;
  height: auto;
  background-size: cover;
  margin-top: 10vh;
  margin-bottom: 12rem;
  background-position: bottom left;
  overflow-x: hidden;
  background-image: linear-gradient(
      243deg,
      rgba(0, 0, 0, 0.856) 0%,
      rgba(0, 0, 0, 0.692) 50%,
      rgba(0, 0, 0, 0.842) 100%
    ),
    url("../assets/images_sobre/SobreHeader1.webp");
  background-position: center;
}

.filosofia {
  display: flex;
  flex-direction: column;
  width: 1400px;

  justify-content: center;
  align-items: center;
  margin: auto;
  color: #ffff;
}

.filosofia h1 {
  margin-top: 6rem;
  color: #67633e;
}

/* Sobre.module.css */
.moreContent {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease;
  opacity: 0;
}

.buttonFilosofia {
  padding: 0.2rem 2rem; /* 2rem padding on the left and right */
  border-radius: 10px;
  background-color: #67633e;
  color: #ffff;
  border: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.moreContent.open {
  max-height: 1500px;
  min-height: auto;
  opacity: 1;
}

.slideRight {
  transform: translateX(-50px);
  animation: slideRightAnimation 2s forwards;
}

.slideLeft {
  transform: translateX(50px);
  animation: slideLeftAnimation 2s forwards;
}
@keyframes slideRightAnimation {
  from {
    transform: translateX(-50%); /* Começa 100% fora da tela à esquerda */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@keyframes slideLeftAnimation {
  from {
    transform: translateX(50%); /* Começa 100% fora da tela à direita */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@media screen and (max-width: 1500px) {
  .containerSobre {
    width: 1200px;
  }
  .filosofia {
    width: 1200px;
  }
  .containerFrase {
    width: 1200px;
  }
  .circleSmall {
    margin-right: 18vw;
  }
}

@media screen and (max-width: 1400px) {
  .circle {
    width: 10rem;
    height: 10rem;
  }
  .circleSmall {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 1300px) {
  .containerSobre {
    width: 1024px;
  }
  .filosofia {
    width: 1024px;
  }
  .containerFrase {
    width: 1024px;
  }
}

@media screen and (max-width: 1250px) {
  .circle {
    width: 9rem;
    height: 9rem;
  }
  .circleSmall {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .containerFrase h1 {
    font-size: 2rem;
    width: 24rem;
  }
}

@media screen and (max-width: 1024px) {
  .containerSobre {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .containerTexto h1 {
    margin-top: 3rem;
  }
  .imgPerfil {
    height: 35rem;
    width: 30rem;

    margin-top: 6rem;
  }
  .imgContainer {
    width: auto;
  }
  .filosofia {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .circle {
    width: 7rem;
    height: 7rem;
    top: 10%;
  }
  .circleSmall {
    width: 2rem;
    height: 2rem;
    top: 16%;
  }

  .containerTexto {
    margin-left: 0;
  }
  .containerTexto p {
    margin-right: 0;
  }
  .containerFrase {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .containerTexto2 {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .containerFrase h1 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .containerFrase h1 {
    font-size: 1.6rem;
    width: 20rem;
  }

  .circle {
    width: 6rem;
    height: 6rem;
  }
  .circleSmall {
    width: 1.8rem;
    height: 1.8rem;
  }

  .imgPerfil {
    height: 30rem;
    width: 25rem;
  }
}

@media screen and (max-width: 650px) {
  .containerFrase h1 {
    font-size: 1.4rem;
    width: 18rem;
  }

  .circle {
    width: 5rem;
    height: 5rem;
  }
  .circleSmall {
    width: 1.6rem;
    height: 1.6rem;
  }

  .imgContainer {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .imgPerfil {
    height: 25rem;
    width: 22rem;
  }
  .circle {
    display: none;
  }
  .circleSmall {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .imgPerfil {
    height: 22rem;
    width: 19rem;
  }
}

@media screen and (max-width: 350px) {
  .containerFrase h1 {
    font-size: 1.1rem;
    width: 14rem;
  }
}

@media screen and (max-width: 300px) {
  .imgPerfil {
    height: 22rem;
    width: 14rem;
  }
}
