.form {
  margin-top: 4rem;
}

.form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  --bs-border-radius: 0;
  color: #fff !important;
}

.form-control::-webkit-input-placeholder {
  color: white !important;
}

/* Define o estilo para o placeholder */
.custom-placeholder::placeholder {
  color: #ffffff !important;
}

/* Opcional: Alterar estilo no foco do input */
.custom-placeholder:focus::placeholder {
  color: #ffffff !important; /* Cor diferente ao focar no campo */
  opacity: 0.7;
}

.button-submit {
  --bs-btn-color: #000000 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  border-radius: 0 !important;
}

.button-submit:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.button-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Espaçamento entre o texto e o spinner */
}

.spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

.button-submit:disabled {
  opacity: 0.7; /* Ou ajuste para um valor de opacidade suave */
  cursor: not-allowed;
  background-color: #007bff; /* Mantenha a cor original do botão */
  color: white;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 1024px) {
  .form {
    margin-top: 1rem;
  }
}

@media (max-width: 250px) {
  .form-control {
    margin-top: 1rem;
    font-size: 0.75rem !important;
  }
}
