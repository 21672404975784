* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: relative;
}

.containerServicios {
  display: flex;
  flex-direction: column;
  max-width: 100vw; /* Limita a largura à tela */
  overflow: hidden;
}

/* CSS para o container dos acordeões */
.subtitleIntro {
  font-size: 1.2rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 0;
}
.titleE {
  font-size: 1.5rem;
  color: #67633e;
  margin-left: 6rem;
}

.contentE {
  font-size: 16px;
  margin-left: 6rem;
  display: flex;
  line-height: 2rem;
}

.containerContent {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.linksAcordeao {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease, transform 0.3s ease;
  padding-left: 0;

  color: #000000;
  text-decoration: underline;
  margin-top: 1rem;
  margin-left: 4rem;
}

.containerLinks a {
  text-decoration: none;
  color: #67633e;
}

#links {
  background-color: #dfdfdf;
  width: auto;
  justify-content: center;
  align-items: center;
  margin-left: 6rem;
  border-radius: 10px;
}

#links a {
  margin-left: 0;
}
.containerLinks {
  margin-bottom: 3rem;
}

ul {
  padding-left: 0 !important;
}
li {
  list-style-type: none;
}

.containerAcordeao {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #dfdfdf;
  margin-top: 3rem;
}
.linksAcordeaoD {
  display: inline-flex;
  align-items: flex-start;
  gap: 5px;
  transition: color 0.3s ease, transform 0.3s ease;

  text-decoration: none;
  color: #000000;
  text-decoration: underline;
  margin-top: 1rem;
  margin-left: 0;
  padding-left: 0;
}

.linksAcordeaoD:hover,
.linksAcordeaoD:focus {
  color: #67633e;
  transform: scale(1.05);
}

.linksAcordeao,
.linksAcordeaoD {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease, transform 0.3s ease;
  padding-left: 0;
  color: #000000;
  text-decoration: underline;
  margin-top: 1rem;
}

.linksAcordeao::after,
.linksAcordeaoD::after {
  content: "→";
  opacity: 1;
  transform: translateX(0px);
  transition: transform 0.3s ease;
}

.linksAcordeao:hover::after,
.linksAcordeaoD:hover::after {
  transform: translateX(10px);
}

.linksAcordeao:hover,
.linksAcordeaoD:hover,
.linksAcordeao:focus,
.linksAcordeaoD:focus {
  color: #67633e;
  transform: scale(1.05);
}
.linksAcordeaoD:hover::after,
.linksAcordeaoD:focus::after {
  opacity: 1;
  transform: scale(1.2);
}

.linksAcordeao:hover,
.linksAcordeao:focus {
  color: #67633e;
  transform: scale(1.05);
}

.linksAcordeao:hover::after,
.linksAcordeao:focus::after {
  opacity: 1;
  transform: scale(1.2);
}

.headerServicios {
  display: flex;
  background: #000000;
  width: 100%;
  color: #ffff;
  top: 10vh;
  height: 70vh;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-bottom: 10vh;
}

.headerServicios h1 {
  margin: auto;
  text-align: center;
  justify-content: center;
}

.containerImg {
  display: flex;
  justify-content: center;
}

.containerImg img {
  display: flex;
  object-fit: cover;
  height: 20rem;
  width: 15vw;
  border-radius: 50px;
  margin-left: 1vw;
  margin-top: 4vh;
}

.containerImg img:hover {
  background: #000000;
}

.containerServicio {
  display: flex;
  flex-direction: row;
  margin-top: 20vh;
  position: relative;
}

.button {
  background: #000000 !important;
  border: #000000 solid 0.5px !important;
}

.button:hover {
  background: #ffff !important;
  color: #000000 !important;
  border: #000000 solid 0.5px !important;
}

.containerServicio {
  width: 1400px;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 6rem;
}

.containerServicio img {
  border-radius: 10px;
  width: 500px;
  height: 600px;
  object-fit: cover;
  transition: opacity 1s ease-in-out 0s;
}

.containerServicioD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1400px;

  margin: auto;
}

.titleD {
  font-size: 1.5rem;
  color: #67633e;
  margin-top: 6rem;

  display: flex;
}

.contentD {
  font-size: 16px;
  margin-right: 6rem;
  line-height: 2rem;
}

.containerServicioD img {
  display: flex;
  border-radius: 10px;
  width: 500px;
  height: 600px;
  object-fit: cover;
  justify-content: flex-end;
  margin-top: 6rem;
}

.containerServicio a {
  display: flex;
  color: #67633e;
  text-decoration: none;

  justify-content: center;
  align-items: center;
}

.containerServicioD a {
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  padding-left: 0;
  color: #67633e;
}
.containerPerguntas {
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.containerLinksD {
  justify-content: center;
  align-self: center;
  margin: auto 0;
  display: flex;
  margin-left: 0;
  flex-direction: row;
  margin-right: 6rem;
  background-color: #dfdfdf;
  border-radius: 10px;
  justify-content: center;
}

.slideRight {
  transform: translateX(-50px);
  animation: slideRightAnimation 2s forwards;
}

.slideLeft {
  transform: translateX(50px);
  animation: slideLeftAnimation 2s forwards;
}
@keyframes slideRightAnimation {
  from {
    transform: translateX(-50%); /* Começa 100% fora da tela à esquerda */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@keyframes slideLeftAnimation {
  from {
    transform: translateX(50%); /* Começa 100% fora da tela à direita */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@media screen and (max-width: 1550px) {
  .containerServicio {
    width: 1200px;
  }

  .containerServicioD {
    width: 1200px;
  }
}

@media screen and (max-width: 1300px) {
  .containerServicio {
    width: 1100px;
  }
  .containerServicioD {
    width: 1100px;
  }

  .linksAcordeao {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .containerServicio {
    width: 1000px;
  }
  .containerServicioD {
    width: 1000px;
  }
  .titleD {
    font-size: 1.3rem;
  }
  .titleE {
    font-size: 1.3rem;
  }

  .linksAcordeao {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .containerAcordeao {
    flex-direction: column;
    align-items: center;
  }
  .containerServicio {
    flex-direction: column;

    max-width: 90%;
  }

  .containerServicio img {
    order: 2; /* Imagem vem depois do conteúdo */
    width: 70%; /* Ajuste a largura da imagem conforme necessário */
    margin: 0 auto; /* Centraliza a imagem */
  }

  .containerServicio p {
    margin-left: 0;
  }

  .contentE {
    margin-left: 0;
  }
  .containerContent {
    justify-content: center;
    align-items: flex-start;
    max-width: 90%;
  }

  .titleE {
    margin-left: 0;
  }

  .containerServicioD {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .containerServicioD {
    flex-direction: column;
    align-items: center;
  }

  .contentD {
    margin-right: 0;
  }
  .containerContentD {
    justify-content: center;
    align-items: flex-start;
  }

  .titleD {
    margin-right: 0;
  }
  .linksAcordeao {
    font-size: 1rem;
  }

  #links {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .containerLinksD {
    margin-left: 0;
    margin-right: 0;
  }
  .containerServicio img {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .containerServicio img {
    width: 90%;
  }
  .containerServicioD img {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .linksAcordeao {
    font-size: 1rem;
  }
}
