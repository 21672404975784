.button {
  position: fixed !important;
  bottom: 2%;
  left: 3%;
  background: #67633e !important;
}

.button:hover {
  background: #000000 !important;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none; /* Evita que o botão seja clicável quando invisível */
}

a {
  position: relative;
}
