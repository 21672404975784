body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.containerIcons {
  display: flex;
  justify-content: center;
  margin-top: 12rem;
  border-radius: 10px;
  margin-bottom: 6rem;
  position: relative;

  width: 100%;
}

.halfCircle {
  height: 4rem;
  width: 8rem;
  background-color: #a9a9a9;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  transform: rotate(-90deg);
  position: absolute;
  right: -2.1%;
}
.circle {
  width: 3.5rem; /* ajuste conforme necessário */
  height: 3.5rem; /* metade da largura */
  background-color: #a9a9a9; /* cor de fundo */
  border-radius: 50%;
  position: absolute;
  top: 70%;
  right: 5%; /* esconder a parte superior do semicírculo */
}

.iconsTexto {
  display: flex;
  flex-direction: row;
}
.icon {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

#iconAdd img {
  width: 6.2rem !important;
  height: 6.2rem !important;
}

.icon img {
  width: 6em;
  height: 6em;
  margin-left: 5rem;
  margin-right: 5rem;
  color: #67633e;
  padding: none;
}
.linkServicios {
  text-decoration: none;
  color: black;
}

.linkServicios:hover {
  color: white;
}

.titles-container {
  flex-direction: column;
  padding: 20px;
  display: flex;
  align-items: flex-start; /* Alinha os títulos à esquerda */
}

.title {
  cursor: pointer;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
  opacity: 0.7;
}

/* Estilo base para os títulos */
.slideTitle {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0.5;
}

/* Estilo para o título ativo */
.activeTitle {
  transform: scale(1.2);
  opacity: 1;
  font-weight: bold;
}

.swiperContainer {
  width: calc(100% - 12rem);
  flex-shrink: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 6rem;
}

.list {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
}

.titleList {
  font-weight: bold;
  margin-left: 2rem;
}

.titleIcon {
  margin: auto;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  color: #a9a9a9;
}

.subtitleIcon {
  margin: auto;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: #000000;
}

.button {
  margin: 1rem auto !important;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 3rem !important;
  border-radius: 30px !important;
  background-color: #000000 !important;
  border: none !important;
  color: #ffffff !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transition: transform 0.5s ease-in-out, background-color 0.9s ease-in-out !important;
}

.button:hover {
  color: #ffffff !important;
  background-color: #67633e !important;
  transform: translateY(-10px) !important;
}

.linhaVertical {
  height: auto; /*Altura da linha*/
  border-left: 1px solid;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #a9a9a9;
}
.imgIntro {
  width: 30em;
  height: 30em;
  object-fit: cover;
  border-radius: 10px;
  transition: opacity 1s ease-in-out 0s;
  opacity: 0;
  transform: translateX(-50%);
}

.imgIntro2 {
  width: 30em;
  height: 30em;
  object-fit: cover;
  border-radius: 10px;
}

.isVisible {
  opacity: 1;
  transform: translateX(0);
}
.fadeIn {
  opacity: 0;
  animation: fadeInAnimation 1s forwards;
}

.slideRight {
  transform: translateX(-50px);
  animation: slideRightAnimation 2s forwards;
}

.slideLeft {
  transform: translateX(50px);
  animation: slideLeftAnimation 2s forwards;
}
@keyframes slideRightAnimation {
  from {
    transform: translateX(-50%); /* Começa 100% fora da tela à esquerda */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}

@keyframes slideLeftAnimation {
  from {
    transform: translateX(50%); /* Começa 100% fora da tela à direita */
    opacity: 0; /* Começa invisível */
  }
  to {
    transform: translateX(0); /* Termina na posição original (0%) */
    opacity: 1; /* Termina completamente visível */
  }
}
.containerIntro {
  margin-left: 12em;
  margin-right: 12em;
  margin-top: 6rem;
  display: flex;
  margin-bottom: 6rem;
  max-width: 100%;
}

.titleIntro {
  font-size: 1.5rem;
  color: #67633e;
  transition: opacity 2s ease, transform 2s ease;
  font-family: "Roboto Medium";
}

.contentBeneficio {
  font-size: 0.8rem;
}
.subtitleIntro {
  font-size: 1.2rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 0;
}
.containerTexto {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  margin-left: 4rem;
  width: 50%;
}

.containerTexto p {
  justify-content: baseline;
  text-align: left;
  margin-top: 0;
}

.containerIntro div {
  display: flex;
  flex-direction: row;
}

.contentPrincipal {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}
.containerPrincipal {
  margin-top: 12rem;

  background-color: #a9a9a9;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  display: flex;
  overflow: hidden;
}

.circleTitle {
  width: 20rem;
  height: 20rem;
  background-color: #67633e;
  border-radius: 50%;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  top: -50%;
  left: 30%;
  z-index: -1;
}

.principal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardShort {
  position: relative;
  background-color: #a9a9a9;
  width: 16rem;
  border-radius: 10px;
  margin: 2rem 1rem;
  filter: drop-shadow(4px 4px 8px #00000057);
  transition: transform 0.5s ease-in-out;
  perspective: 1000px;
  height: 22rem;
}

.contentCard,
.cardBack {
  position: absolute;
  backface-visibility: hidden;
  border-radius: 8px;
  transition: transform 0.6s linear;
  padding: 20px;
  height: 100%;
  width: 100%; /* Adicionando padding para melhor leitura */
}

.cardShort svg {
  color: #ffff;
}

.contentCard {
  color: #000000; /* Cor escura para o texto */
  display: flex;
  align-items: center; /* Alinha verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
  flex-direction: column; /* Organiza os itens em coluna */

  background-size: cover;
}

.cardBack {
  background: #f1f1f1; /* Cor mais clara para contraste */
  color: #333; /* Cor escura para o texto */
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
}

#cardTratamiento {
  position: relative;
  background-color: #ffffff;
  transition: transform 0.5s ease-in-out;
  max-width: 19rem;
  width: 20rem;
  min-height: 22rem;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.63);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titlePrincipal {
  width: 100%;
  margin-top: 2rem;
}

.conteudoTratamiento {
  font-size: 18px;
  padding: 1rem;
}
.cardBig {
  width: 19rem;
  height: 22rem;
}

.cardAlternativo {
  height: 26rem;
  width: 21rem !important;
}

.cardAlternativo2 {
  height: 32rem;
  width: 21rem !important;
}
#cardTratamiento::after {
  content: "";
  width: 180px; /* Tamanho da semicircunferência */
  height: 180px; /* Altura deve ser metade da largura para formar uma semicircunferência */
  background-color: #a9a9a9; /* Cor do elemento */
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  transform: translate(-0%, -50%); /* Ajuste para centralizar */
  z-index: -1; /* Definido para garantir que apareça atrás do conteúdo do card */
}

#cardTratamiento:hover {
  transform: translateY(-10px);
}

#cardTratamiento .titlePrincipal {
  color: #67633e; /* Verde para o título */
}
.cardShort:not(#cardTratamiento):hover .contentCard {
  transform: rotateY(-180deg);
}

.cardShort:not(#cardTratamiento):hover .cardBack {
  transform: rotateY(0deg);
}

.titlePrincipalContainer {
  display: flex;
  justify-content: center;
  color: #000000;
}

.titlePrincipal {
  color: #ffffff;
  justify-content: center;
  display: flex;
  align-items: center;
  /* Automaticamente ajusta as margens superior e inferior */
  text-align: center; /* Garante que o texto esteja centralizado horizontalmente */
  width: 100%;
  font-size: 1.5rem;
  font-family: "Roboto Medium";
}

.conteudo {
  font-size: 0.9rem;
  padding: 1rem;
}
.contentCard {
  padding: 1.2rem;
}

.titleDescricao {
  margin-bottom: 0.5px;
  font-weight: bold;
}

.descricao {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.content p {
  font-size: 0.9rem;
}

#foto .contentCard {
  background-position: center right;
}

.containerProcesso {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 6rem;
  margin-bottom: 6rem;
  margin-left: 12rem;
  margin-right: 12rem;
  max-width: 100%;
  align-items: center;
}
.descricao h4 {
  margin-top: 2rem;
  color: #67633e;

  margin-right: 2rem;
  font-family: "Roboto Medium";
}

.containerList {
  display: flex;
  flex-direction: row;
}

.iconList span {
  background-color: #67633e;
  height: 58px;
  width: 58px;
  border-radius: 10px;
  display: flex;
}

.iconList svg {
  width: 2.1rem;
  height: 2.1rem;
  display: flex;
  justify-content: center;
  margin: auto;
  color: #ffff;
}
.quoteProcesso {
  width: 16rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  text-align: center;
  font-size: 0.8rem;
}

.contentList {
  margin-left: 2rem;
}

.checkIcon {
  margin-right: 0.5rem;
  color: #67633e;
}
.linhaVerticalIcons {
  width: 3px;
  height: 15rem;
  background-color: #67633e;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 1.4rem;
  position: absolute;
}
.circleBlack {
  width: 7.563rem;
  height: 7.563rem;
  background-color: #ffff;
  border-radius: 50%;
  position: absolute;
  margin-left: 1rem;
  filter: drop-shadow(4px 4px 8px #00000057);
}

.circleContainer {
  position: relative;
}

.circleContainer h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #67633e;
}

.containerPlus {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  background-color: #a9a9a9;
  max-width: 100%;
}

.containerPlus h4 {
  display: flex;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
  color: #ffff;
}

.contentPlus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 2rem;
}
#buttonPlus {
  margin-bottom: 1.4rem !important;
  background: #ffffff !important;
  color: #000000 !important;
  margin: 0 !important;
  margin-bottom: 2rem !important;
}
#plusAlternativo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

#buttonPlus:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

.containerPerguntas {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.containerPerguntas h2 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.circlePlus a {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  text-decoration: none;
  color: #ffff;
  width: 7rem;
}

.circlePlus {
  width: 8.3rem;
  height: 8.3rem;
  background-color: #67633e;
  border-radius: 100%;
  display: flex;
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
  margin-left: 1rem;
  margin-right: 1rem;

  flex-direction: column;
}

.circlePlus:hover {
  transform: scale(1.1);
  opacity: 1;
  background-color: #000000;
}

.containerPlus {
  display: flex;
  flex-direction: column;
}

.containerAcordeao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a9a9a988;
}

.containerAcordeao h1 {
  margin-top: 6rem;
}

.contentAcordeao {
  display: flex; /* Ativa o layout flexível */
  flex-direction: row; /* Alinha os filhos em linha */
  justify-content: center; /* Distribui espaço igualmente ao redor dos itens */
  align-items: start; /* Centraliza os itens verticalmente */
  width: auto;
  margin: auto;
  margin-bottom: 6rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 1700px) {
  .circle {
    width: 2rem;
    height: 2rem;
  }

  .linhaVerticalIcons {
    height: 16rem;
  }

  .icon img {
    margin-left: 4.4rem;
    margin-right: 4.4rem;
  }
}
@media screen and (max-width: 1600px) {
  .circle {
    width: 2rem;
    height: 2rem;
  }

  .conteudo {
    font-size: 0.8rem;
  }
  .circle {
    top: 70%;
  }
  .halfCircle {
    width: 7.8rem;
    height: 3.8rem;
  }
  .linhaVerticalIcons {
    height: 20rem;
  }
  .icon img {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
}

@media screen and (max-width: 1500px) {
  .conteudo {
    font-size: 0.7rem;
  }

  .imgIntro,
  .imgIntro2 {
    width: 27em;
    height: 27em;
  }

  .halfCircle {
    width: 6rem;
    height: 3rem;
  }
  .circle {
    width: 1.3rem;
    height: 1.3rem;
    right: 3%;
  }
  .titleList {
    font-size: 1rem;
  }
  .contentList {
    font-size: 0.8rem;
  }

  .slideContent {
    width: 28rem;
  }
}

@media screen and (max-width: 1400px) {
  .imgIntro,
  .imgIntro2 {
    width: 25em;
    height: 25em;
  }

  .icon img {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .containerIntro {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .conteudo {
    font-size: 1rem;
  }

  .icon img {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .slideContent h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1350px) {
  .imgIntro,
  .imgIntro2 {
    width: 23em;
    height: 23em;
  }

  .containerIntro {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .containerProcesso {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .icon img {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .swiperImg {
    width: 170px;
    height: 170px;
  }

  .iconList span {
    width: 50px;
    height: 50px;
  }

  .mySwiper {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .titleCard {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .icon img {
    margin-left: 2.7rem !important;
    margin-right: 2.7rem !important;
  }
  .button {
    font-size: 0.8rem !important;
  }

  .cardShort {
    width: 14rem;
    height: 20rem;
  }

  .cardBig {
    width: 18rem;
    height: 20rem;
  }

  .content p {
    font-size: 0.8rem;
  }
  .descricao h4 {
    width: 20rem;
    font-size: 1.4rem;
  }

  .cardAlternativo {
    height: 24rem;
  }
  .containerProcesso {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .containerIntro {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .titleDescricao {
    font-size: 0.9rem;
  }

  .iconList svg {
    width: 1.9rem;
    height: 1.9rem;
  }

  .halfCircle {
    width: 5rem;
    height: 3rem;
  }
  .circle {
    width: 1.2rem;
    height: 1.2rem;
    right: 2%;
  }
  .contentList p {
    font-size: 18px;
  }

  #headerSurco {
    background-position: center !important;
  }
}

@media screen and (max-width: 1100px) {
  .principal {
    display: flex;
    flex-direction: column;
  }

  .cardShort {
    width: 18rem;
  }
  .containerIcons .iconsTexto .button {
    display: flex;
    flex-direction: column;
  }
  .iconsTexto {
    flex-direction: column;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon img {
    width: 7rem;
    height: 7rem;
  }

  .circle {
    top: 70%;
    width: 3rem;
    height: 3rem;
    right: 10%;
  }
  .halfCircle {
    width: 10rem;
    height: 5rem;
    right: -4.5%;
    top: 20%;
  }

  .imgIntro,
  .imgIntro2 {
    width: 21em;
    height: 21em;
  }

  .button {
    font-size: 1rem !important;
  }

  .cardShort {
    height: 26rem;
    width: 19rem;
  }
  .content p {
    font-size: 1rem;
  }
  .mySwiper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .titleCard {
    margin-left: 1rem;
  }

  .containerIntro {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .swiperImg {
    width: 10rem;
    height: 10rem;
  }
  .content p {
    font-size: 16px;
  }
}

@media (hover: none) and (pointer: coarse), screen and (max-width: 1024px) {
  .listSwiper {
    padding-left: 0;
  }

  .slideContent h3 {
    display: flex;
    justify-content: center;
  }

  .mySwiper {
    height: 34rem;
  }
  .slideContent {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .button:hover,
  .cardShort:hover,
  .circlePlus:hover,
  #cardTratamiento:hover {
    background-color: inherit; /* Mantém a cor de fundo original */
    color: inherit; /* Mantém a cor original do texto */
    transform: none; /* Remove qualquer transformação aplicada no hover */
    transition: none; /* Desativa transições para evitar efeitos indesejados */
  }
  .swiperContainer {
    width: calc(100% - 6rem);
  }
  .mySwiper {
    margin-left: 0;
    margin-right: 0;
  }

  .swiper-slide {
    padding: 0 5px; /* Ajusta o padding interno */
  }
  .titleCard h4 {
    line-height: 2rem;
  }
  #cardTratamiento {
    width: 18rem;
  }
  .circleTitle {
    left: 10%;
  }
}

@media screen and (max-width: 950px) {
  .swiperContainer {
    flex-direction: column;
  }
  .circle {
    top: 70%;
    width: 3rem;
    height: 3rem;
    right: 10%;
  }
  .halfCircle {
    width: 9rem;
    height: 4.5rem;
    right: -4.7%;

    top: 20%;
  }
  .containerIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mySwiper {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .imgIntro,
  .imgIntro2 {
    margin-bottom: 3rem;
  }
  .titleIntro {
    text-align: left;
    justify-content: center;
    margin: auto;
  }
  .containerTexto {
    display: flex;

    align-items: center;
    text-align: left;
    margin: auto;
  }

  .titleDescricao {
    font-size: 0.9rem;
  }

  .containerList p {
    font-size: 1rem;
  }

  .titleIntro {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .swiperImg {
    width: 8rem;
    height: 8rem;
  }
}
@media screen and (max-width: 850px) {
  #headerLifting {
    width: 100%;
  }

  .containerProcesso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .descricao h4 {
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .halfCircle {
    width: 8rem;
    height: 4rem;
    right: -4.5%;
    top: 20%;
  }
  .circle {
    width: 2rem;
    height: 2rem;
    right: 10%;
  }

  .titleDescricao {
    font-size: 1.2rem;
  }

  .titleList {
    font-size: 1rem;
  }

  .contentListSwiper {
    font-size: 0.8rem;
  }

  .listTratamiento {
    padding: 0;
  }

  .descricao {
    margin: 0;
  }
  #cardTratamiento {
    margin-bottom: 2rem;
    height: 100%;
  }
}

/* Esconde o swiperContainer em telas maiores que 768px */
/* @media (min-width: 768px) {
  .swiperContainerMobile {
    display: none;
  }
} */

@media screen and (max-width: 768px) {
  .halfCircle {
    width: 6rem; /* Adjust the width of the half circle */
    height: 3rem; /* Adjust the height of the half circle */
    right: -2%; /* Adjust the position of the half circle */
  }

  .circle {
    width: 2rem; /* Adjust the width of the circle */
    height: 2rem; /* Adjust the height of the circle */
    right: 5%; /* Adjust the position of the circle */
  }

  .button {
    margin-left: 2rem; /* Adjust the left margin of the button */
    margin-right: 2rem; /* Adjust the right margin of the button */
    margin-top: 1rem !important; /* Adjust the top margin of the button */
  }

  .containerIcons {
    display: flex;
    flex-direction: column;
  }
  .iconsTexto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .halfCircle {
    right: -3.6%;
  }
  .circle {
    top: 40%;
  }

  .contentPrincipal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .linhaVerticalIcons {
    display: none;
  }
  .mySwiper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20rem;

    margin: auto 0;
  }

  .swiper {
    height: 34rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .contentListSwiper p {
    font-size: 0.7rem;
  }
  .titleList {
    font-size: 0.9rem;
  }
  .containerProcesso {
    margin-left: 0;
    margin-right: 0;
    padding: 0 10px; /* Ajuste de padding para evitar overflow */
    overflow-x: hidden; /* Esconde qualquer overflow horizontal */
    display: flex;
    flex-direction: column; /* Muda flex-direction para column */
  }
  #cardTratamiento {
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.418);
    filter: none; /* Sombra mais suave para pequenos dispositivos */
  }
  .swiperContainer {
    width: calc(100% - 3rem);
  }

  .containerTexto {
    width: auto;
  }
  .titleIntro {
    margin-left: 0;
  }
}
@media screen and (max-width: 650px) {
  .swiperImg {
    width: 120px;
    height: 120px;
  }
  .slideContent p {
    font-size: 0.7rem;
  }
  .slideContent h3 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .circlePlus {
    width: 7rem;
    height: 7rem;
  }

  .circlePlus a {
    font-size: 1rem;
  }

  .halfCircle {
    right: -5%;
  }
  .circlePlus {
    width: 8.3rem;
    height: 8.3rem;
  }
}

@media screen and (max-width: 550px) {
  .imgIntro,
  .imgIntro2 {
    width: 18rem;
    height: 18rem;
  }
  .circleTitle {
    width: 10rem;
    height: 10rem;
  }
  .halfCircle {
    display: none;
  }
  .circle {
    display: none;
  }

  .containerPrincipal {
    width: 100%;
  }
  .iconsTexto {
    width: 100%;
  }

  .containerIcons {
    margin-left: none;
    margin-right: none;
  }
  .linhaVertical {
    display: none;
  }
  .icon img {
    width: 6rem;
    height: 6rem;
  }

  .content p {
    font-size: 0.8rem;
  }

  .contentPlus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .circlePlus {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .firstSwiperSlide {
    height: 38rem;
  }
  #buttonPlus {
    margin: 0 !important;
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  .containerIcons {
    margin-top: 6rem;
  }
  .containerProcesso {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .slideContent p {
    font-size: 0.6rem;
  }
  .titleList {
    font-size: 0.8rem;
  }

  .swiperContainer {
    width: calc(100% - 1rem);
  }
}
@media screen and (max-width: 500px) {
  .iconsTexto {
    display: flex;
    flex-direction: column;
  }
  .circle {
    display: none;
  }
  .firstSwiperSlide {
    height: 48rem;
  }

  .contentList {
    margin-left: 1rem;
  }

  #plusAlternativo {
    flex-direction: column;
  }
  #swiperAjuste > .sliderContainer {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 450px) {
  body,
  html {
    width: 100%; /* Garantindo que o body e o html ocupem toda a largura da tela */
    overflow-x: hidden; /* Ocultando o overflow horizontal */
  }

  .containerIcons,
  .containerIntro,
  .containerProcesso,
  .containerPerguntas {
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  .descricao {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .containerIntro {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .containerIcons {
    margin-top: 3rem;
  }

  .icon img,
  .halfCircle,
  .circle {
    width: auto; /* Ajusta a largura para auto para que se ajuste dentro do container */
    max-width: 100%; /* Garante que não ultrapassem a largura do container */
  }

  .imgIntro,
  .imgIntro2 {
    margin-top: 3rem;
    width: 80vw; /* Ajusta a imagem para usar toda a largura disponível */
    height: 90vw;
  }

  .containerPerguntas {
    margin-top: 6rem;
  }
  #cardTratamiento {
    margin-top: 3rem;
  }
  .content p {
    font-size: 16px;
  }
  .containerTexto p {
    font-size: 18px;
  }
}

#headerRelleno {
  object-position: left;
}

.conteudoAdd span {
  font-size: 16px;
}

.conteudoAdd strong {
  font-size: 16px;
}
@media screen and (max-width: 400px) {
  .firstSwiperSlide {
    height: 48rem;
  }
  .containerList p {
    margin-right: 1rem;
  }

  .iconList svg {
    width: 30px;
    height: 30px;
  }
  .iconList span {
    width: 40px;
    height: 40px;
  }

  .imgIntro,
  .imgIntro2 {
    width: 16rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .descricao {
    width: 350px;
  }

  .descricao h4 {
    width: 100%;
  }

  .content,
  .description,
  .containerIcons,
  .containerIntro {
    padding: 0 5px; /* Reduz o padding para evitar overflow horizontal */
  }

  /* Corrigir outros elementos que podem estar causando overflow */
  .some-other-class {
    padding: 0;
    margin: 0;
  }

  .swiperImg {
    width: 100px;
    height: 100px;
  }

  #cardTratamiento {
    width: calc(
      100vw - 20px
    ); /* Calcula 100% da largura da tela menos 20px para margem */
  }

  #cardTratamiento {
    /* Garantir que o cardTratamiento ocupe a largura disponível, ajustando as margens */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 3rem;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 350px) {
  .cardShort {
    width: 17rem;
  }
  .cardBack p {
    font-size: 16px;
  }
  .descricao {
    width: 300px;
  }
  .titlePrincipalContainer {
    text-align: center;
  }
  .imgIntro,
  .imgIntro2 {
    width: 14rem;
    height: 20rem;
  }
  #cardTratamiento {
    height: auto; /* Permitir que o card ajuste sua altura ao conteúdo */
    min-height: 24rem; /* Manter uma altura mínima se o conteúdo for pequeno */
  }
}

@media screen and (max-width: 350px) {
  .cardBack p {
    font-size: 14px;
  }
}
