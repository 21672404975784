.buttonBlack {
  border-radius: 30px !important;

  border: none !important;
  background-color: #000000 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transition: transform 0.5s ease-in-out, background-color 0.9s ease-in-out !important;
}

.buttonBlack:hover {
  transform: translateY(-10px) !important;
  background-color: rgb(255, 255, 255);
}
