.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .content {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 4rem; 
    height: 4rem;
    cursor: pointer;
  }
  
 
  
  .giftContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
 .gift{
    width: 8rem;
 }
  
 
 
