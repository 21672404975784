.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #67633e;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-out;
  z-index: 1100; /* High z-index to cover everything */
}

.loading-overlay.out {
  opacity: 0;
  visibility: hidden; /* Use visibility to remove from the layout */
}
.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-content h1 {
  color: #ffffff;
  font-size: 24px;
}

.dot {
  opacity: 0;
  animation: dot 1.5s infinite;
}

.dot1 {
  animation-delay: 0s;
}
.dot2 {
  animation-delay: 0.5s;
}
.dot3 {
  animation-delay: 1s;
}

@keyframes dot {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* Transição de saída - afunda em um círculo */
.loading-overlay.out {
  animation: circleOut 0.5s forwards ease-in-out;
}

@keyframes circleOut {
  0% {
    clip-path: circle(100% at 50% 50%);
  }
  100% {
    clip-path: circle(0% at 50% 50%);

    visibility: hidden;
  }
}

.logo-loading {
  width: 8rem;
  height: auto;
  margin-bottom: 2rem;
}
