.post-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form > h2 {
  margin-bottom: 1rem;
}

form > label {
  align-self: flex-start;
  font-weight: bold;
  margin-top: 1rem;
}

form > input,
form > .descricao,
form > select,
.section input,
.section .ql-container {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

form > .ql-container {
  height: 200px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
}

.section input {
  margin-bottom: 1.5rem;
}

.paragraph {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.section-buttons,
.subsection-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.buttonAdd,
.buttonRemove,
.buttonCriar,
.buttonAddParagraph,
.buttonAddSubsection {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: max-content;
  margin: auto;
}

.buttonAdd,
.buttonAddParagraph {
  background-color: #67633e;
  color: #fff;
  margin-bottom: 1.5rem;
}

.buttonAddSubsection {
  background-color: #a9a9a9;
  color: #000000;
  border: 0.5px solid #67633e;
}
.buttonAdd:hover,
.buttonAddParagraph:hover,
.buttonAddSubsection:hover {
  background-color: #494628;
  color: #ffff;
}

.buttonRemove {
  background-color: #000000;
  color: #fff;
  margin-top: 0.5rem;
  width: max-content;
  margin: auto;
}

.buttonRemove:hover {
  background-color: #c82333;
}

.buttonCriar {
  background-color: #67633e;
  color: #fff;
  margin-top: 2rem;
}

.buttonCriar:hover {
  background-color: #218838;
}

.upload-files-container {
  width: 100%;
  padding: 1rem;
  border: 1px dashed #ddd;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 1.5rem;
  background-color: #fff;
}

.drag-file-area {
  position: relative;
  cursor: pointer;
}

.icon-upload svg {
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
}

.dynamic-message {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.label {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.browse-files {
  position: relative;
  color: #67633e;
  font-weight: bold;
  cursor: pointer;
}

.default-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.photo-preview-img {
  width: 400px;
}

.descricao .ql-editor p {
  margin: 0 0 1em;
}

.iconButton svg {
  margin-left: 0.5rem;
}
