.buttonGreen {
  border-radius: 30px !important;

  border: none !important;
  background-color: #67633e !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transition: transform 0.5s ease-in-out, background-color 0.9s ease-in-out !important;
}

.buttonGreen:hover {
  transform: translateY(-10px) !important;
  background-color: black;
}
